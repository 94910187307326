import { RouteWithName } from '@/router';
import { AsyncComponentLoader } from '@/router/useRouterHelpers';

export const routes: RouteWithName[] = [
  {
    path: `/link/:linkName`,
    name: `LinkMateLayout`,
    component: () => import('../../pages/linkMate/LinkMateLayout.vue'),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: 'opportunity_warm',
        name: 'BusinessInformationFlowWarm',
        component: AsyncComponentLoader(() => import('./UnicornPageViewerView.vue')),
        props: { type: 'opportunity_warm' },
      },
      {
        path: 'opportunity_cold',
        name: 'BusinessInformationFlowCold',
        component: AsyncComponentLoader(() => import('./UnicornPageViewerView.vue')),
        props: { type: 'opportunity_cold' },
      },
    ],
  },
];
