import { LocationQueryRaw, RouteParamsRaw, RouteRecordRaw, Router, useRouter } from 'vue-router';
import { AvailableRoutes, availableRoutesArray } from '.';
import { defineAsyncComponent, ref } from 'vue';
import { Tenant, useTenantService } from '@/services/tenantService';
import LoaderWithProgressBar from '@/components/LoaderWithProgressBar.vue';

export function useRouterHelpers(routerInit?: Router) {
  const router = routerInit || useRouter();
  const { getTenantName, getTenantFromRoute } = useTenantService();

  const currentRoute = ref(router.currentRoute);

  const getNewTenantPath = (newTenant: Tenant, currentPath: string) => {
    const oldTenant = getTenantFromRoute();
    if (!oldTenant) return `/${newTenant}${currentPath}`;
    return currentPath.replace(oldTenant, newTenant);
  };
  const changeRoute = (newRoute: AvailableRoutes) => {
    router.push({ name: getRouteName(newRoute) });
  };
  const changeRouteWithPath = (path: string) => {
    router.push({ path });
  };
  const changeRouteWithProps = (newRoute: AvailableRoutes, props: RouteParamsRaw) => {
    router.push({ name: getRouteName(newRoute), params: props });
  };
  const changeRouteWithQueryParams = (newRoute: AvailableRoutes, queryObject: LocationQueryRaw) => {
    router.push({ name: getRouteName(newRoute), query: queryObject });
  };
  const changeRouteWithHash = (newRoute: AvailableRoutes, anchor: string) => {
    router.push({ name: getRouteName(newRoute), hash: `#${anchor}` });
  };
  const getRouteName = (route: AvailableRoutes) => {
    const tenant = getTenantName();
    return tenant ? `${tenant}${route}` : route;
  };

  const isAvailableRoute = (route: string): route is AvailableRoutes => {
    return availableRoutesArray.includes(route as AvailableRoutes);
  };

  return {
    changeRoute,
    changeRouteWithPath,
    changeRouteWithProps,
    changeRouteWithQueryParams,
    changeRouteWithHash,
    currentRoute,
    getRouteName,
    getNewTenantPath,
    isAvailableRoute,
  };
}

export const addPrefixToRoutes = (routes: RouteRecordRaw[], prefix: Tenant, isChildren = false) => {
  return routes.map(route => {
    if (route.children) {
      route.children = addPrefixToRoutes(route.children, prefix, true);
    }
    if (!isChildren) {
      route.path = `/${prefix}${route.path}`;
      if (route.alias && typeof route.alias === 'string') {
        route.alias = `/${prefix}${route.alias}`;
      }
      if (route.alias && Array.isArray(route.alias)) {
        route.alias = route.alias.map(alias => `/${prefix}${alias}`);
      }
    }
    if (route.redirect && typeof route.redirect === 'string') {
      route.redirect = `/${prefix}${route.redirect}`;
    }
    route.name = `${prefix}${String(route.name)}`;
    return route;
  });
};

export const AsyncComponentLoader = (importCallback: () => Promise<any>) =>
  defineAsyncComponent({
    loader: importCallback,
    loadingComponent: LoaderWithProgressBar,
    delay: 200,
  });
