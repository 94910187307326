<template>
  <Menu as="div" :class="['relative']">
    <div data-cy="baseDropdown">
      <MenuButton
        v-slot="{ open }"
        :class="[
          inputClass
            ? inputClass
            : 'flex w-full items-center justify-between focus:ring-2 focus:ring-primary active:ring-2 active:ring-primary bg-white px-4 py-3.5 text-sm font-medium rounded-lg',
          disabled && 'opacity-50',
        ]"
        :disabled="disabled"
      >
        <div v-if="selected" class="flex items-center justify-center gap-2">
          <FormKitIcon :icon="selected.icon" class="w-8" />
          <div v-if="!selectedNotLabeled" class="text-gray-700">{{ t(displayedSelected.label) }}</div>
        </div>
        <div v-else class="flex items-center justify-center gap-2">
          <div v-if="!selectedNotLabeled" class="text-gray-500 truncate">{{ placeholder }}</div>
        </div>
        <div class="ml-2 flex">
          <FormKitIcon icon="ChevronDownIcon" :class="['h-4 w-4', open && 'rotate-180']" aria-hidden="true" />
        </div>
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        data-cy="menuItemsBaseDropdown"
        as="div"
        class="shadow-3xl max-h-96 absolute z-50 mt-1 w-full origin-top-right overflow-x-hidden overflow-y-auto bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        :class="menuStyle"
      >
        <div class="">
          <MenuItem as="div" v-for="(option, index) in options" :key="index" v-slot="{ active }" @click="handleInput(option)">
            <div
              :class="[
                active || selected?.value === option.value ? 'bg-green-200 text-gray-900' : 'text-gray-700',
                'flex cursor-pointer items-center gap-2 px-3 py-2 text-sm',
                itemStyle,
              ]"
            >
              <div v-if="option.icon" class="flex items-center justify-center">
                <FormKitIcon :icon="option.icon" class="w-8" />
              </div>
              <div data-cy="baseDropdownText" class="flex w-full justify-between">
                <div>
                  <p class="truncate">{{ t(option.label) }}</p>
                  <p v-if="option.subLabel" class="text-gray-400">{{ option.subLabel }}</p>
                </div>
                <div v-if="showValue">{{ option.value }}</div>
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts" generic="T = string">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { FormKitIcon } from '@formkit/vue';
import { computed, onMounted, ref } from 'vue';
import { FormKitNode } from '@formkit/core';
import { useTypedI18n } from '../../plugins/i18nPlugin';
import { watch } from 'vue';
import { Ref } from 'vue';

export interface BaseDropdownOption<T> {
  label: string;
  value: T;
  subLabel?: string;
  icon?: string;
}

type FormkitContext = {
  selected: BaseDropdownOption<T>;
  selectOptions: BaseDropdownOption<T>[];
  node: FormKitNode;
  showValue?: boolean;
  selectedNotLabeled?: boolean;
  inputClass?: string[] | string;
  menuStyle?: string;
  itemStyle?: string;
  placeholder?: string;
  disabled?: boolean;
};

const props = defineProps<{
  context: FormkitContext;
}>();

const { t } = useTypedI18n();
const selected = ref<BaseDropdownOption<T> | undefined>(props.context.selected) as Ref<BaseDropdownOption<T> | undefined>;
const displayedSelected = computed(
  () => props.context.selectOptions.find(option => option.value === selected.value?.value) || props.context.selected
);
const selectedNotLabeled = ref(props.context.selectedNotLabeled);
const inputClass = ref(typeof props.context.inputClass === 'string' ? props.context.inputClass : props.context.inputClass?.join(' '));
const menuStyle = ref(props.context.menuStyle);
const itemStyle = ref(props.context.itemStyle);
const showValue = ref(props.context.showValue);
const placeholder = ref(props.context.placeholder || t('utils.select_option'));
const options = computed(() => props.context.selectOptions);
const disabled = computed(() => props.context.disabled);

watch(
  () => props.context.selected,
  value => {
    handleInput(value);
  }
);

const handleInput = (option: BaseDropdownOption<T>) => {
  selected.value = option;
  props.context.node.input(option);
};

onMounted(() => {
  props.context.node.input(selected.value);
});
</script>
