export default {
  global: {
    outer: 'col-span-1 sm:col-span-3',
    label: 'block text-sm font-medium text-gray-700',
    input:
      'shadow-sm px-4 py-3.5 focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base sm:text-sm border-gray-300 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed formkit-invalid:border-red-500 formkit-invalid:bg-red-500 formkit-invalid:bg-opacity-10 focus:formkit-invalid:ring-red-500 focus:formkit-invalid:border-red-500',
    messages: 'w-full flex justify-start pt-2 ',
    message: 'text-xs text-red-500 pl-2',
    help: 'text-xs mt-2',
  },

  checkbox: {
    fieldset: '$reset  ',
    help: '$reset  ',
    inner: '$reset  ',
    input:
      '$reset rounded-full focus:ring-white hover:ring-white checked:bg-green-600 checked:focus:bg-green-600 checked:hover:bg-green-600 hover:bg-green-600 active:focus:bg-green-600 active:bg-green-600',
    label: '$reset ml-2 my-auto font-normal text-sm ',
    legend: '$reset text-base font-semibold',
    loaderIcon: '$reset hidden',
    message: '$reset ',
    messages: '$reset ',
    outer: '$reset border-b border-solid pb-3',
    prefixIcon: '$reset ',
    suffixIcon: '$reset ',
    decorator: '$reset ',
    decoratorIcon: 'hidden',
    wrapper: 'flex my-1 opacity-80',
  },
  search: {
    help: '$reset',
    inner: 'flex items-center border border-gray-200 rounded-lg focus-within:border-primary',
    input: '$reset w-full border-none focus:ring-0 rounded-lg placeholder-gray-400',
    label: '$reset ml-2 my-auto font-normal text-sm ',
    legend: '$reset text-base font-semibold',
    message: '$reset ',
    messages: '$reset ',
    outer: '$reset flex',
    prefixIcon: '$reset ml-2 text-gray-400',
    suffixIcon: '$reset cursor-pointer mr-4 text-gray-400',
    wrapper: 'w-full',
  },
  tel: {
    input:
      '$reset pl-0 w-full text-base sm:text-sm rounded-lg border-0 ring-0 focus:ring-0 focus:border-0 disabled:opacity-30 disabled:cursor-not-allowed',
    inner:
      'flex w-full items-center rounded-lg justify-between bg-white px-4 py-1.5 border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed shadow-sm',
  },
};
