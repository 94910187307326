import { routes as empireRoutes } from '../views/empire/routes';
import { config as empireConfig } from '../views/empire/config';
import { routes as automationsuiteRoutes } from '../views/automationsuite/routes';
import { config as automationsuiteConfig } from '../views/automationsuite/config';
import { routes as dbmRoutes } from '../views/dbm/routes';
import { config as dbmConfig } from '../views/dbm/config';
import { routes as rocknrollRoutes } from '../views/rocknroll/routes';
import { config as rocknrollConfig } from '../views/rocknroll/config';
import { routes as iconcommunityRoutes } from '../views/iconcommunity/routes';
import { config as iconcommunityConfig } from '../views/iconcommunity/config';
import { routes as workflowRoutes } from '../views/workflow/routes';
import { config as workflowConfig } from '../views/workflow/config';
import { routes as bombshellRoutes } from '../views/bombshell/routes';
import { config as bombshellConfig } from '../views/bombshell/config';
import { routes as makelifewowteamRoutes } from '../views/makelifewowteam/routes';
import { config as makelifewowteamConfig } from '../views/makelifewowteam/config';
import { routes as impactRoutes } from '../views/impact/routes';
import { config as impactConfig } from '../views/impact/config';
import { routes as goldenglobalteamRoutes } from '../views/goldenglobalteam/routes';
import { config as goldenglobalteamConfig } from '../views/goldenglobalteam/config';
import { routes as diamondclubRoutes } from '../views/diamondclub/routes';
import { config as diamondclubConfig } from '../views/diamondclub/config';
import { routes as farmasiteamRoutes } from '../views/farmasiteam/routes';
import { config as farmasiteamConfig } from '../views/farmasiteam/config';
import { routes as teamgoatRoutes } from '../views/teamgoat/routes';
import { config as teamgoatConfig } from '../views/teamgoat/config';
import { routes as soluniRoutes } from '../views/soluni/routes';
import { config as soluniConfig } from '../views/soluni/config';
import { routes as ketoretoRoutes } from '../views/ketoreto/routes';
import { config as ketoretoConfig } from '../views/ketoreto/config';
import { routes as positiveglobalchangeRoutes } from '../views/positiveglobalchange/routes';
import { config as positiveglobalchangeConfig } from '../views/positiveglobalchange/config';
import { routes as frazerRoutes } from '../views/frazer/routes';
import { config as frazerConfig } from '../views/frazer/config';
import { routes as retiredyoungRoutes } from '../views/retiredyoung/routes';
import { config as retiredyoungConfig } from '../views/retiredyoung/config';
import { routes as gm10legacyRoutes } from '../views/gm10legacy/routes';
import { config as gm10legacyConfig } from '../views/gm10legacy/config';
import { routes as alphauniverseRoutes } from '../views/alphauniverse/routes';
import { config as alphauniverseConfig } from '../views/alphauniverse/config';
import { RouteRecordRaw } from 'vue-router';
import { Tenant } from '@/services/tenantService';

import { InterestPageTypes } from '@/services/linkMateLinkService';
import { LeadInterest, LeadMembership } from '@/services/leadDataService';
import { SourceOptions } from '@/services/leadDataService';
import { MenuItems } from '@/components/Menu/Navigation.vue';
import { AvailableBundles } from '@/pages/ProspectingToolsSettingsPage.vue';

export type TenantConfig = {
  hideLeaderboard: boolean;
  hideLeadMagnetAnalytic: boolean;
  hideInfoPageAnalytic: boolean;
  hideShopLink: boolean;
  hideLastOrder: boolean;
  showRegistrationLink: boolean;
  showBundles: AvailableBundles[];
  customCsvImportService: string[];
  infoPageIdsToShow: InterestPageTypes[];
  leadRadarLink: string;
  deprecatingOldInfoPages: boolean;
  postHogDashboardLink: string;
  UNICORN_PAGE_URL: string;
  interestsToShow: LeadInterest[];
  membershipsToShow: LeadMembership[];
  sourcesToShow: SourceOptions[];
  menuItemsToHide: MenuItems[];
  showSSP: boolean;
  isB2C: boolean;
  hideColdInfoPage: boolean;
  showRequestedLeadsInSchedulerComponent: boolean;
  hideInfoPages: boolean;
};

export const tenantConfigMap: Record<Tenant, { prefix: Tenant; customRoutes: RouteRecordRaw[]; config: TenantConfig }> = {
  empire: {
    prefix: 'empire',
    customRoutes: empireRoutes,
    config: empireConfig,
  },
  automationsuite: {
    prefix: 'automationsuite',
    customRoutes: automationsuiteRoutes,
    config: automationsuiteConfig,
  },
  dbm: {
    prefix: 'dbm',
    customRoutes: dbmRoutes,
    config: dbmConfig,
  },
  rocknroll: {
    prefix: 'rocknroll',
    customRoutes: rocknrollRoutes,
    config: rocknrollConfig,
  },
  workflow: {
    prefix: 'workflow',
    customRoutes: workflowRoutes,
    config: workflowConfig,
  },
  bombshell: {
    prefix: 'bombshell',
    customRoutes: bombshellRoutes,
    config: bombshellConfig,
  },
  makelifewowteam: {
    prefix: 'makelifewowteam',
    customRoutes: makelifewowteamRoutes,
    config: makelifewowteamConfig,
  },
  impact: {
    prefix: 'impact',
    customRoutes: impactRoutes,
    config: impactConfig,
  },
  goldenglobalteam: {
    prefix: 'goldenglobalteam',
    customRoutes: goldenglobalteamRoutes,
    config: goldenglobalteamConfig,
  },
  diamondclub: {
    prefix: 'diamondclub',
    customRoutes: diamondclubRoutes,
    config: diamondclubConfig,
  },
  farmasiteam: {
    prefix: 'farmasiteam',
    customRoutes: farmasiteamRoutes,
    config: farmasiteamConfig,
  },
  teamgoat: {
    prefix: 'teamgoat',
    customRoutes: teamgoatRoutes,
    config: teamgoatConfig,
  },
  soluni: {
    prefix: 'soluni',
    customRoutes: soluniRoutes,
    config: soluniConfig,
  },
  ketoreto: {
    prefix: 'ketoreto',
    customRoutes: ketoretoRoutes,
    config: ketoretoConfig,
  },
  positiveglobalchange: {
    prefix: 'positiveglobalchange',
    customRoutes: positiveglobalchangeRoutes,
    config: positiveglobalchangeConfig,
  },
  iconcommunity: {
    prefix: 'iconcommunity',
    customRoutes: iconcommunityRoutes,
    config: iconcommunityConfig,
  },
  frazer: {
    prefix: 'frazer',
    customRoutes: frazerRoutes,
    config: frazerConfig,
  },
  retiredyoung: {
    prefix: 'retiredyoung',
    customRoutes: retiredyoungRoutes,
    config: retiredyoungConfig,
  },
  gm10legacy: {
    prefix: 'gm10legacy',
    customRoutes: gm10legacyRoutes,
    config: gm10legacyConfig,
  },
  alphauniverse: {
    prefix: 'alphauniverse',
    customRoutes: alphauniverseRoutes,
    config: alphauniverseConfig,
  },
};

export const tenantConfig = Object.values(tenantConfigMap);
