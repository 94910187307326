import { RouteWithName } from '@/router';
import { AsyncComponentLoader } from '@/router/useRouterHelpers';

export const routes: RouteWithName[] = [
  {
    path: `/link/:linkName`,
    name: `LinkMateLayout`,
    component: () => import('../../pages/linkMate/LinkMateLayout.vue'),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: 'business',
        name: 'BusinessInformationFlow',
        component: AsyncComponentLoader(() => import('./UnicornPageViewerView.vue')),
        props: { type: 'business' },
      },
      {
        path: 'collagen',
        name: 'ProductInformationFlowCollagen',
        component: AsyncComponentLoader(() => import('./UnicornPageViewerView.vue')),
        props: { type: 'collagen' },
      },
      {
        path: 'nutrition',
        name: 'ProductInformationFlowNutrition',
        component: AsyncComponentLoader(() => import('./UnicornPageViewerView.vue')),
        props: { type: 'nutrition' },
      },
    ],
  },
];
