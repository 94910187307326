import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';
import { envs } from '../env';
import { useUserStore } from '@/stores/userStore';
import { useTenantService } from '@/services/tenantService';

const VITE_API_URL = envs.VITE_API_URL;
const VITE_CONTACT_ME_FORM_API_TOKEN = envs.VITE_CONTACT_ME_FORM_API_TOKEN;

//declare header type
export type Header = {
  headers: {
    Authorization: string;
    'Content-Type'?: string;
  };
};

export interface StrapiDTO<T> {
  id: number;
  attributes: T;
}

export interface StrapiResponse<T> {
  data: T;
  meta: {
    pagination: {
      limit: number;
      start: number;
      total: number;
    };
  };
}

export type StrapiResponseDTO<T> = StrapiResponse<T> & AxiosResponse<T>;

export interface StrapiError {
  data: null;
  error: {
    status: number;
    name: string;
    message: string;
    details?: {
      errors?: {
        message: string;
        name: string;
        path: string[];
      }[];
    };
  };
}

export interface StrapiObjectBody<T> {
  data: T;
}

export function useAxiosUtils() {
  const userStore = useUserStore();

  const getHeaders = (): Header | undefined => {
    const token = userStore.loggedInInfo?.jwt;
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${userStore.loggedInInfo?.jwt}`,
        },
      };
    }
    return undefined;
  };

  const getHeadersFormData = (): Header | undefined => {
    const token = userStore.loggedInInfo?.jwt;
    if (token) {
      return {
        headers: {
          Authorization: `Bearer ${userStore.loggedInInfo?.jwt}`,
          'Content-Type': 'multipart/form-data',
        },
      };
    }
    return undefined;
  };

  const getApiToken = (): Header => {
    return {
      headers: {
        Authorization: `Bearer ${VITE_CONTACT_ME_FORM_API_TOKEN}`,
      },
    };
  };

  const getAuth = (withApiKey: boolean) => {
    if (withApiKey) {
      return getApiToken();
    }
    return getHeaders();
  };

  return {
    getHeaders,
    getApiToken,
    getHeadersFormData,
    getAuth,
  };
}

const onResponseFulfilled = (response: AxiosResponse) => {
  return response;
};

const onResponseRejected = (error: unknown) => {
  if (!(error instanceof AxiosError)) {
    return Promise.reject(error);
  }

  // Add Sentry context
  const enhancedError = addErrorContext(error);

  // Handle 401 logout
  if (error.response?.status === 401) {
    useUserStore().logout();
  }

  // Track 4xx and 5xx errors
  if (error.response?.status && error.response.status >= 400) {
    Sentry.captureException(enhancedError, {
      level: error.response.status >= 500 ? 'error' : 'warning',
      fingerprint: [
        error.response.status.toString(),
        error.config?.url?.split('?')[0] || 'unknown_endpoint',
        error.config?.method?.toUpperCase() || 'unknown_method',
      ],
    });
  }

  return Promise.reject(error);
};

const addErrorContext = (error: AxiosError) => {
  const userStore = useUserStore();
  const endpoint = error.config?.url?.split('?')[0];
  const servicePath = endpoint?.split('/')[1];

  // Set global tags
  Sentry.setTags({
    endpoint,
    method: error.config?.method?.toUpperCase(),
    status_code: error.response?.status,
    service: servicePath,
    environment: envs.VITE_FIREBASE_ENV,
  });

  // Set user context
  if (userStore.loggedInInfo?.user?.id) {
    Sentry.setUser({
      id: userStore.loggedInInfo.user.id.toString(),
      tenant: userStore.loggedInInfo.user.tenant?.tenant,
      subscription: userStore.subscriptionsStatus?.status,
    });
  }

  // Build error context
  const errorContext = {
    request: {
      url: error.config?.url,
      method: error.config?.method,
      data: error.config?.data ? JSON.stringify(error.config.data).length : 0,
    },
    response: {
      status: error.response?.status,
      data: error.response?.data ? JSON.stringify(error.response.data).length : 0,
    },
    user: {
      id: userStore.loggedInInfo?.user?.id,
      tenant: userStore.loggedInInfo?.user?.tenant?.tenant,
    },
    subscription: {
      status: userStore.subscriptionsStatus?.status,
      features: userStore.subscriptionsStatus?.features,
    },
    timestamp: new Date().toISOString(),
  };

  Object.assign(error, { sentryContext: errorContext });
  return error;
};

const axiosClient = axios.create({
  baseURL: VITE_API_URL as string,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosClient.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export default axiosClient;
