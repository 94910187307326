export default `<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 4H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 9H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 14H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 5.5H4.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.50004 5.99997C3.36743 5.99997 3.24025 5.94729 3.14649 5.85353C3.05272 5.75976 3.00004 5.63258 3.00004 5.49997V3.08497L2.72404 3.22297C2.60549 3.28238 2.46819 3.29226 2.34236 3.25043C2.21652 3.20861 2.11245 3.11852 2.05304 2.99997C1.92904 2.75297 2.03004 2.67597 2.27704 2.55297L3.27704 2.05297C3.35323 2.01501 3.43785 1.99712 3.52289 2.00101C3.60793 2.0049 3.69057 2.03044 3.76297 2.07521C3.83538 2.11997 3.89516 2.18248 3.93664 2.25682C3.97813 2.33115 3.99995 2.41484 4.00004 2.49997V5.49997C4.00004 5.63258 3.94736 5.75976 3.85359 5.85353C3.75982 5.94729 3.63265 5.99997 3.50004 5.99997Z" fill="currentColor"/>
<path d="M4.5 10.5H2.5C2.5 10.266 4.35 9.42405 4.35 8.26605C4.30778 8.05899 4.19835 7.87168 4.03872 7.73322C3.87909 7.59475 3.6782 7.51289 3.46726 7.50035C3.25632 7.48781 3.04714 7.54529 2.87222 7.66386C2.69731 7.78243 2.56646 7.95546 2.5 8.15605" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.5 14.846C2.56637 15.0466 2.69713 15.2196 2.87197 15.3383C3.04681 15.4569 3.25594 15.5145 3.46687 15.5021C3.6778 15.4896 3.87872 15.4079 4.03842 15.2696C4.19811 15.1312 4.30765 14.944 4.35 14.737C4.36107 14.5612 4.30545 14.3876 4.19423 14.251C4.08302 14.1144 3.92439 14.0248 3.75 14C3.92542 13.9779 4.08561 13.8891 4.19733 13.7521C4.30905 13.615 4.36372 13.4402 4.35 13.264C4.30765 13.057 4.19811 12.8697 4.03842 12.7314C3.87872 12.593 3.6778 12.5113 3.46687 12.4989C3.25594 12.4864 3.04681 12.544 2.87197 12.6627C2.69713 12.7813 2.56637 12.9544 2.5 13.155" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
