import { TenantConfig } from '@/router/tenantConfig';

export const config: TenantConfig = {
  infoPageIdsToShow: ['business-isagenix', 'collagen-isagenix', 'nutrition-isagenix'],
  UNICORN_PAGE_URL: 'https://iconcommunity.linkmate.one',
  postHogDashboardLink: 'https://us.posthog.com/shared/uiX5xkV0YHetDgGn3_Uj1xku1eqSWQ',
  customCsvImportService: [],
  deprecatingOldInfoPages: false,
  leadRadarLink: '',
  hideLastOrder: true,
  hideShopLink: false,
  hideInfoPageAnalytic: false,
  hideLeadMagnetAnalytic: false,
  hideLeaderboard: false,
  showRegistrationLink: false,
  showBundles: ['premium-pack', 'reset-pack', 'healthy-breakfast', '60-doses-pack', '40-doses-pack'],
  menuItemsToHide: [],
  sourcesToShow: ['Manually Created', 'CONTACT_ME_FORM', 'INFORMATION_FLOW', 'LEAD_MAGNET', 'CSV'],
  membershipsToShow: ['Lead', 'Customer', 'Promoter', 'Lost'],
  interestsToShow: ['business', 'product'],
  hideColdInfoPage: false,
  showSSP: false,
  isB2C: false,
  showRequestedLeadsInSchedulerComponent: true,
  hideInfoPages: false,
};
