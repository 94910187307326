let toastShown = false;

function injectCriticalCss() {
  if (document.getElementById('saga-update-toast-style')) return;

  const style = document.createElement('style');
  style.id = 'saga-update-toast-style';
  style.textContent = `
    #saga-update-toast {
      position: fixed;
      bottom: 16px;
      right: 16px;
      z-index: 9999;
      background: white;
      border-radius: 8px;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      padding: 16px;
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-family: system-ui, -apple-system, sans-serif;
      transform: translateY(150%);
      transition: all 0.3s ease-in-out;
      width: 410px;
      opacity: 1;
      visibility: visible;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
    }

    #saga-update-toast.exit {
      transform: translateX(200%);
      opacity: 0;
      visibility: hidden;
    }

    #saga-update-toast:hover {
      background: #f9fafb;
    }

    #saga-update-toast .icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #4F46E5;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color: white;
      box-sizing: border-box;
    }

    #saga-update-toast .message {
      color: #1f2937;
      font-weight: 500;
      line-height: 1.4;
      margin: 0;
      flex: 1;
      font-size: 0.9rem;
      box-sizing: border-box;
    }

    @media (max-width: 480px) {
      #saga-update-toast {
        width: calc(100% - 32px);
        margin: 0 16px;
      }
    }
  `;

  document.head.appendChild(style);
}

function handleReload() {
  if ('caches' in window) {
    caches
      .keys()
      .then(names => Promise.all(names.map(name => caches.delete(name))))
      .catch(console.error)
      .finally(() => window.location.reload());
  } else {
    window.location.reload();
  }
}

function showUpdateToast() {
  if (toastShown) return;
  toastShown = true;

  injectCriticalCss();

  const toast = document.createElement('div');
  toast.id = 'saga-update-toast';
  toast.innerHTML = `
    <div class="icon">↻</div>
    <span class="message">Your SAGA got updated, please reload the window</span>
  `;

  const handleClick = () => {
    toast.classList.add('exit');
    setTimeout(handleReload, 300);
  };

  toast.onclick = handleClick;
  document.body.appendChild(toast);

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      toast.style.transform = 'translateY(0)';
    });
  });

  // Auto-reload after 4 seconds
  setTimeout(handleClick, 4000);
}

function isChunkError(event) {
  if (!event) return false;

  const message = event.message || event.error?.message || '';
  // Detect "Failed to load module script" errors
  if (/Failed to load module script.*text\/html/i.test(message)) {
    return true;
  }

  // Handle errors from <script> and <link> tags
  const target = event.target;
  if (target?.tagName === 'SCRIPT' && target.type === 'module') {
    return /-[A-Za-z0-9]{8,}\.js$/.test(target.src) || target.src.includes('/assets/');
  }

  // Handle failed link preloading (Vite/Webpack) - more specific condition
  if (target?.tagName === 'LINK' && target.rel === 'modulepreload' && event.type === 'error') {
    // Changed from OR to AND - must satisfy both conditions
    return /-[A-Za-z0-9]{8,}\.js$/.test(target.href) && target.href.includes('/assets/');
  }

  return false;
}

function initializeErrorHandler() {
  let errorCount = 0;
  const ERROR_THRESHOLD = 3;
  let resetTimer;

  function handleError(event) {
    if (!isChunkError(event)) return;

    console.warn('[Chunk Error Detected]', {
      url: window.location.pathname,
      message: event.message || event.reason?.message,
    });

    errorCount++;
    clearTimeout(resetTimer);

    resetTimer = setTimeout(() => (errorCount = 0), 2000);

    if (errorCount >= ERROR_THRESHOLD) {
      showUpdateToast();
    }

    event.preventDefault();
    return false;
  }

  window.addEventListener('error', handleError, true);
  window.addEventListener('unhandledrejection', event => {
    if (event.reason?.message) {
      handleError({ message: event.reason.message, preventDefault: event.preventDefault.bind(event) });
    }
  });
}

// Initialize immediately
initializeErrorHandler();

// Global access
window.handleReload = handleReload;
window.showUpdateToast = showUpdateToast;
