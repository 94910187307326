<template>
  <Menu as="div" class="relative w-full">
    <div>
      <MenuButton
        class="flex w-full items-center justify-between rounded-full bg-white px-4 py-1 text-sm font-medium"
        :data-cy="`filter-${title}`"
      >
        <div class="truncate">
          {{ title }}
        </div>
        <div class="flex">
          <FormKitIcon icon="ChevronDownIcon" class="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
        </div>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        as="div"
        class="absolute max-h-96 overflow-y-auto z-10 mt-1 w-full origin-top-right rounded-lg overflow-x-hidden bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <MenuItem
          as="div"
          class="flex flex-col"
          v-for="option in options"
          :key="option.value"
          v-slot="{ active }"
          :data-cy="`filter-${option.value}`"
          @click="handleInput(option)"
        >
          <div
            v-if="props.context.multi"
            class="hover:bg-green-100 hover:text-gray-900 text-gray-700 flex cursor-pointer items-center gap-2 rounded-lg px-2 py-2 text-sm"
          >
            <div
              :class="[
                'flex shrink-0 h-5 w-5 items-center justify-center rounded-full border-2 border-gray-100',
                isSelected(option) ? 'border-none bg-green-600 text-white' : '',
              ]"
            >
              <FormKitIcon v-if="isSelected(option)" icon="CheckIcon" class="h-3 w-3" />
            </div>

            <div class="flex items-center gap-1 sm:gap-2" :class="option.style">
              <p :title="t(option.label)">{{ t(option.label) }}</p>
              <FormKitIcon v-if="option.icon" :icon="option.icon" class="w-8" />
            </div>
          </div>
          <div
            v-else
            :class="[
              active || isSelected(option) ? 'bg-green-100 text-gray-900' : 'text-gray-700',
              ' flex cursor-pointer items-center gap-1 truncate rounded-lg px-4 py-2 text-sm sm:gap-2',
            ]"
          >
            {{ t(option.label) }}
            <FormKitIcon v-if="option.icon" :icon="option.icon" class="w-5" />
          </div>
          <div>
            <MenuItem
              as="div"
              :class="[
                'hover:bg-green-100 hover:text-gray-900 text-gray-700 flex cursor-pointer items-center gap-2 rounded-lg text-sm p-2 pl-6',
              ]"
              v-for="subOption in option.subOptions"
              :key="subOption.value"
              @click.stop="handleInput(subOption, option)"
            >
              <template v-if="props.context?.multi">
                <div
                  :class="[
                    'flex h-5 w-5 shrink-0 items-center justify-center rounded-full border-2 border-gray-100',
                    isSelected(subOption) ? 'border-none bg-green-600 text-white' : '',
                  ]"
                >
                  <FormKitIcon v-if="isSelected(subOption)" icon="CheckIcon" class="h-3 w-3" />
                </div>

                <div class="flex justify-between items-center gap-1 w-full truncate sm:gap-2">
                  <p class="truncate" :title="t(subOption.label)">{{ t(subOption.label) }}</p>
                  <FormKitIcon v-if="subOption.icon" :icon="subOption.icon" class="w-8 shrink-0" />
                </div>
              </template>
            </MenuItem>
          </div>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { FormKitIcon } from '@formkit/vue';
import { computed, ref } from 'vue';
import { FormKitNode } from '@formkit/core';
import { FilterDropdownOption } from '../../plugins/formKitPlugin';
import { useTypedI18n } from '@/plugins/i18nPlugin';

type FormkitContext = {
  title: string;
  filterOptions: FilterDropdownOption[];
  multi: boolean;
  selected: string;
  node: FormKitNode;
};

const props = defineProps<{
  context: FormkitContext;
}>();

const { t } = useTypedI18n();
const title = computed(() => props.context.title);
const options = computed(() => props.context.filterOptions);

const handleInput = (option: FilterDropdownOption, parentOption?: FilterDropdownOption) => {
  if (parentOption) {
    props.context.node.input({ option, parentOption });
    return;
  }
  props.context.node.input(option);
};

const isSelected = (filter: FilterDropdownOption) => {
  if (props.context.multi)
    return (
      props.context.selected.includes(filter.value) ||
      filter.subOptions?.every(subOption => props.context.selected.includes(subOption.value))
    );
  else return props.context.selected === filter.value;
};
</script>
