import { RouteWithName } from '@/router';
import { AsyncComponentLoader } from '@/router/useRouterHelpers';

export const routes: RouteWithName[] = [
  {
    path: '/ketones/:id/:lang?',
    name: 'ChatBotRedirect',
    props: true,
    component: AsyncComponentLoader(() => import('../../pages/ChatBotRedirect.vue')),
    meta: { requiresAuth: false },
  },
  {
    path: `/link/:linkName`,
    name: `LinkMateLayout`,
    component: () => import('../../pages/linkMate/LinkMateLayout.vue'),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: 'opportunity',
        name: 'BusinessInformationFlow',
        redirect: to => ({ path: `link/${to.params.linkName}/info-page/business` }),
      },
      {
        path: 'info',
        name: 'ProductInformationFlow',
        alias: 'ketoinfo',
        redirect: to => ({ path: `link/${to.params.linkName}/info-page/product` }),
      },
    ],
  },
];
