export default `<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 6.868V6H5V7H5.942L6 6.868Z" fill="currentColor"/>
<path d="M5 4H4V5H5V4Z" fill="currentColor"/>
<path d="M6.817 5H6V6H6.38L6.817 5Z" fill="currentColor"/>
<path d="M3 6H2V7H3V6Z" fill="currentColor"/>
<path d="M4 5H3V6H4V5Z" fill="currentColor"/>
<path d="M5 7H4V8H5V7Z" fill="currentColor"/>
<path d="M4 11.439V11H3V12H3.755L4 11.439Z" fill="currentColor"/>
<path d="M3 12H2V13H3V12Z" fill="currentColor"/>
<path d="M3 9H2V10H3V9Z" fill="currentColor"/>
<path d="M3 15H2V16H3V15Z" fill="currentColor"/>
<path d="M4.63 10H4V11H4.192L4.63 10Z" fill="currentColor"/>
<path d="M4 8H3V9H4V8Z" fill="currentColor"/>
<path d="M10.832 4.2L11 4.582V4H10.708C10.7533 4.06411 10.7947 4.1309 10.832 4.2Z" fill="currentColor"/>
<path d="M7 4.582L7.168 4.2C7.20522 4.13087 7.24663 4.06408 7.292 4H7V4.582Z" fill="currentColor"/>
<path d="M8.00001 13H7.68301L7.33201 13.8C7.29477 13.8691 7.25336 13.9359 7.20801 14H8.00001V13Z" fill="currentColor"/>
<path d="M13 2H12V3H13V2Z" fill="currentColor"/>
<path d="M12 3H11V4H12V3Z" fill="currentColor"/>
<path d="M9 3H8V3.282C8.30238 3.10088 8.64754 3.00355 9 3Z" fill="currentColor"/>
<path d="M3 3H2V4H3V3Z" fill="currentColor"/>
<path d="M7 2H6V3H7V2Z" fill="currentColor"/>
<path d="M4 2H3V3H4V2Z" fill="currentColor"/>
<path d="M6 3H5V4H6V3Z" fill="currentColor"/>
<path d="M10 2H9V3H10V2Z" fill="currentColor"/>
<path d="M16 14H15V15H16V14Z" fill="currentColor"/>
<path d="M13.4471 10.174L13.4691 10.225L13.4721 10.232L13.8081 11H14.0001V10H13.3701L13.4471 10.174Z" fill="currentColor"/>
<path d="M14 7H13V8H14V7Z" fill="currentColor"/>
<path d="M16 5H15V6H16V5Z" fill="currentColor"/>
<path d="M15 6H14V7H15V6Z" fill="currentColor"/>
<path d="M16 8H15V9H16V8Z" fill="currentColor"/>
<path d="M15 9H14V10H15V9Z" fill="currentColor"/>
<path d="M3.775 14H3V15H4V14.314C3.91489 14.217 3.83953 14.1118 3.775 14Z" fill="currentColor"/>
<path d="M15 3H14V4H15V3Z" fill="currentColor"/>
<path d="M12.0001 6.868V6H11.6201L12.0001 6.868Z" fill="currentColor"/>
<path d="M16 2H15V3H16V2Z" fill="currentColor"/>
<path d="M13 5H12V6H13V5Z" fill="currentColor"/>
<path d="M14 4H13V5H14V4Z" fill="currentColor"/>
<path d="M12.9331 9H13.0001V8H12.4951L12.9331 9Z" fill="currentColor"/>
<path d="M10 14H9V15H10V14Z" fill="currentColor"/>
<path d="M9 15H8V16H9V15Z" fill="currentColor"/>
<path d="M6 14.926V15H7V14.316C6.73529 14.615 6.38691 14.8275 6 14.926Z" fill="currentColor"/>
<path d="M6 15H5V16H6V15Z" fill="currentColor"/>
<path d="M10.668 13.8L10.317 13H10V14H10.792C10.7467 13.9359 10.7053 13.8691 10.668 13.8Z" fill="currentColor"/>
<path d="M12 15H11V16H12V15Z" fill="currentColor"/>
<path d="M14.3321 12.2C14.4422 12.4523 14.4987 12.7247 14.4981 13H15.0001V12H14.2451L14.3321 12.2Z" fill="currentColor"/>
<path d="M15 15H14V16H15V15Z" fill="currentColor"/>
<path d="M16 11H15V12H16V11Z" fill="currentColor"/>
<path d="M5.5 13L9 5L12.5 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.6299 11H6.37988" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
