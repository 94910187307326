import { createI18n, I18n, useI18n } from 'vue-i18n';
import { AccountLanguage, AccountLanguageArray, DEFAULT_LANGUAGE, LATIN_AMERICAN_REGION_CODE } from '../services/useLanguageService';
import enTranslations from '../locales/en/en.lang.json';
import { Tenant, useTenantService } from '@/services/tenantService';
import { tenantsArray } from '@/env';

// eslint-disable-next-line @typescript-eslint/ban-types
export let i18n: I18n<{}, {}, {}, string, false>;

export type MessageSchema = typeof enTranslations;

const loadedVariants: string[] = [];
const loadedLocales: string[] = [];
const loadingPromises: Record<string, Promise<void> | undefined> = {};

async function initI18nObject() {
  const fallbackLocales: Record<string, AccountLanguage[]> = {};
  tenantsArray.forEach(tenant => {
    AccountLanguageArray.forEach(lang => {
      fallbackLocales[lang + '_' + tenant] = [lang, DEFAULT_LANGUAGE];
    });
  });

  i18n = createI18n({
    locale: DEFAULT_LANGUAGE,
    legacy: false,
    globalInjection: true,
    fallbackLocale: {
      mx: ['es', DEFAULT_LANGUAGE],
      ...fallbackLocales,
      default: [DEFAULT_LANGUAGE],
    },
    warnHtmlMessage: false,
    missingWarn: false,
    fallbackWarn: false,
  });
  const tenant = useTenantService().getTenantName();
  await loadMessages(DEFAULT_LANGUAGE, tenant);
}

export async function configureI18n() {
  initI18nObject();

  await setI18nLanguage();

  return i18n;
}

async function loadMessages(locale: string, variant?: string) {
  const locale_variant = variant ? locale + '_' + variant : locale;
  if (loadingPromises[locale_variant]) {
    return loadingPromises[locale_variant];
  }

  loadingPromises[locale_variant] = new Promise(async resolve => {
    if (locale === 'mx') loadMessages('es', variant);

    const filesName = ['lang', 'video', 'scripts'];
    if (!loadedLocales.includes(locale)) {
      const allMessages = await loadLocales(locale, filesName);
      i18n.global.setLocaleMessage(locale, allMessages);
      loadedLocales.push(locale);
    }
    if (variant && !loadedVariants.includes(locale_variant)) {
      const variants = await loadVariants(locale, variant, filesName);
      i18n.global.setLocaleMessage(locale_variant, variants);
      loadedVariants.push(locale_variant);
    }
    loadingPromises[locale_variant] = undefined;
    resolve();
  });
  return loadingPromises[locale_variant];
}

const loadLocales = async (locale: string, filesName: string[]) => {
  let allMessages = {};
  for (const fileName of filesName) {
    try {
      const currentFile = await import(`./../locales/${locale}/${locale}.${fileName}.json`);
      allMessages = { ...allMessages, ...currentFile.default };
    } catch (error) {
      console.warn(fileName + ' is not a valid locale for ' + locale);
    }
  }
  return allMessages;
};

const loadVariants = async (locale: string, variant: string, filesName: string[]) => {
  let variants = {};
  for (const fileName of filesName) {
    try {
      const currentVariantFile = await import(`./../locales/${locale}/variants/${locale}.${variant}.${fileName}.json`);
      variants = { ...variants, ...currentVariantFile.default };
    } catch (error) {
      console.warn('No file found for variant ' + variant + ' in ' + locale + ' for ' + fileName);
    }
  }
  return variants;
};

export function getCurrentRegion() {
  const browserLang = window.navigator.language;
  const locale = new Intl.Locale(browserLang);
  const parsedRegion = locale.region?.toLowerCase() || locale.language.toLowerCase();
  return parsedRegion;
}

export function getCurrentLocale() {
  const browserLang = window.navigator.language;
  const baseLang = browserLang.split('-')[0];
  const curLang = AccountLanguageArray.find(availableLang => availableLang === baseLang);
  if (!curLang) {
    return DEFAULT_LANGUAGE;
  }
  if (curLang === 'mx') return 'es';
  return curLang;
}

export const getLeadLocale = () => {
  const locale = new Intl.Locale(window.navigator.language);
  const browserRegion = locale.region?.toLowerCase();
  if (browserRegion === LATIN_AMERICAN_REGION_CODE) return 'mx';
  return AccountLanguageArray.find(availableLang => availableLang === browserRegion) || locale.language.toLowerCase();
};

export async function setI18nLanguage(locale?: AccountLanguage | null, tenant?: Tenant) {
  if (!locale) {
    locale = getCurrentLocale();
  }
  if (!i18n) {
    await initI18nObject();
  }
  if (!tenant) {
    tenant = useTenantService().getTenantName();
  }
  await loadMessages(locale, tenant);

  i18n.global.locale.value = locale + '_' + tenant;

  return locale;
}

export const useTypedI18n = () => {
  return useI18n<{ message: MessageSchema }>();
};
