import { useUserStore } from '../stores/userStore';
import http, { StrapiDTO, StrapiResponse, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import { UserDTO } from './userDataService';
import { useUtilsService } from './utilsService';
import qs from 'qs';

export interface SocialOption {
  type: ContactsTypes;
  visibleName: string;
  example: string;
  sanitizeLink?: (value: string) => string;
}
export const socialOptions: SocialOption[] = [
  {
    type: 'instagram',
    example: 'instagramusername',
    visibleName: 'instagram',
  },
  {
    type: 'facebook',
    example: 'https://facebook.com/facebookpageurl',
    visibleName: 'facebook',
  },
  {
    type: 'linkedin',
    example: 'https://linkedin.com/in/username',
    visibleName: 'linkedIn',
  },
  {
    type: 'twitter',
    example: 'yourtwitterhandle',
    visibleName: 'twitter',
  },
  {
    type: 'whatsapp',
    example: '+1 xxxxxxxxxx',
    visibleName: 'whatsapp',
    sanitizeLink: (value: string) => {
      return value;
    },
  },
  {
    type: 'telegram',
    example: 'https://t.me/telegramUsername',
    visibleName: 'telegram',
  },
  {
    type: 'snapchat',
    example: 'https://www.snapchat.com/add/yourusername',
    visibleName: 'snapchat',
  },
  {
    type: 'mail',
    example: 'your@emailaddress.com',
    visibleName: 'email',
  },
  {
    type: 'tiktok',
    example: '@tiktokusernamemple',
    visibleName: 'tikTok',
    sanitizeLink: (value: string) => {
      if (!value.startsWith('@')) {
        value = '@' + value;
      }
      return value;
    },
  },
  {
    type: 'spotify',
    example: 'https://open.spotify.com/artist/artistname',
    visibleName: 'spotify',
  },
  {
    type: 'youtube',
    example: 'https://youtube.com/channel/youtubechannelurl',
    visibleName: 'youTube',
  },
  {
    type: 'messenger',
    example: 'https://m.me/username.',
    visibleName: 'messenger',
  },
  {
    type: 'number',
    example: '+1 xxxxxxxxxx',
    visibleName: 'SMS',
  },
  {
    type: 'threads',
    example: 'https://www.threads.net/@username',
    visibleName: 'threads',
  },
  {
    type: 'signal',
    example: '+1 xxxxxxxxxx',
    visibleName: 'signal',
  },
];

export interface ThumbnailOptions {
  type: 'Icons' | 'Images';
  element: string;
}

export const InterestTypesArray = [
  'product_warm_impact',
  'opportunity_warm_impact',
  'interestIncome',
  'interestProduct',
  'product-automationsuite',
  'business-automationsuite',
  'product-rocknroll',
  'business-bombshell',
  'nutrition-isagenix',
  'collagen-isagenix',
  'business-isagenix',
  'opportunity_warm',
  'opportunity_cold',
  'product_goldenglobalteam',
  'business_goldenglobalteam',
] as const;

export type InterestPageTypes = (typeof InterestTypesArray)[number];

export type ContactsTypes =
  | 'instagram'
  | 'facebook'
  | 'linkedin'
  | 'twitter'
  | 'whatsapp'
  | 'telegram'
  | 'snapchat'
  | 'skype'
  | 'tiktok'
  | 'spotify'
  | 'youtube'
  | 'mail'
  | 'number'
  | 'threads'
  | 'messenger'
  | 'signal';

export type BundleTypes = 'bundleBest' | 'bundleBetter' | 'bundleGood';
export type GeneralSubTypes = 'generalLink' | 'healthVideo' | 'interestVideo';

export type SubTypes = InterestPageTypes | ContactsTypes | BundleTypes | GeneralSubTypes;

export type LinkMateLinkOut = LinkMateLink & {
  profileTree?: number;
  user_library_setting?: number;
};

export type DefaultLinkMateLinkOut = Pick<LinkMateLink, 'enabled' | 'isDefault' | 'mainType' | 'subType' | 'visibleName' | 'value'>;

export const allowedContacts: SubTypes[] = ['number', 'instagram', 'facebook', 'whatsapp', 'telegram', 'mail'];

export const AnimationsArray = ['wiggle', 'bounce', 'pulse'] as const;
export type Animations = (typeof AnimationsArray)[number];

export interface LinkMateLink {
  mainType: 'contact' | 'link' | 'shop' | 'video' | 'contactMeForm' | 'informationFlow' | 'bundle' | 'leadMagnet' | 'registration';
  order: number;
  subType: string;
  value: string;
  visibleName?: string;
  createdAt?: Date;
  enabled?: boolean;
  icon?: string;
  isDefault?: boolean;
  thumbnail?: string;
  updatedAt?: Date;
  animationType?: Animations | null;
  countryCode?: string;
}

const shouldLinkBeSanitized = (payload: Partial<LinkMateLink>) => {
  if (
    payload.subType === 'facebook' ||
    payload.subType === 'generalLink' ||
    payload.subType === 'linkedin' ||
    payload.subType === 'youtube' ||
    payload.subType === 'snapchat' ||
    payload.subType === 'bundleBest' ||
    payload.subType === 'bundleBetter' ||
    payload.subType === 'bundleGood' ||
    payload.subType === 'telegram' ||
    payload.subType === 'messenger' ||
    payload.subType === 'threads' ||
    payload.subType === 'spotify'
  ) {
    return true;
  } else return false;
};

export function useLinkMateLinkService() {
  const userStore = useUserStore();
  const { getHeaders } = useAxiosUtils();
  const { trimAndSanitizeLink } = useUtilsService();

  async function createInitialDefaultLinkMateLinks(linkMateId: number, user: UserDTO) {
    const setupPayload: LinkMateLinkOut[] = [];

    if (user.email) {
      setupPayload.push({
        value: user.email,
        icon: 'mail',
        enabled: true,
        order: 3,
        isDefault: true,
        mainType: 'contact',
        subType: 'mail',
        profileTree: linkMateId,
        visibleName: getSocialLinkVisibleName('mail'),
      });
    }
    setupPayload.push({
      value: 'contactMeForm',
      enabled: true,
      order: 0,
      isDefault: true,
      mainType: 'contactMeForm',
      subType: 'generalLink',
      profileTree: linkMateId,
    });

    await createBatchLinkMateLinks(setupPayload);
  }

  async function createLinkMateLink(payload: LinkMateLinkOut) {
    if (shouldLinkBeSanitized(payload)) {
      payload.value = trimAndSanitizeLink(payload.value) || payload.value;
    }
    const res = await http.post<StrapiResponseDTO<StrapiDTO<LinkMateLink>>>(`/api/profile-links`, { data: { ...payload } }, getHeaders());
    return res.data;
  }

  async function createBatchLinkMateLinks(payload: LinkMateLinkOut[]): Promise<StrapiResponse<StrapiDTO<LinkMateLink>>[]> {
    const createdLinks: Promise<StrapiResponseDTO<StrapiDTO<LinkMateLink>>>[] = [];
    payload.forEach(link => {
      if (shouldLinkBeSanitized(link)) {
        link.value = trimAndSanitizeLink(link.value) || link.value;
      }
      createdLinks.push(createLinkMateLink(link));
    });

    const allLinks = await Promise.all(createdLinks);
    return allLinks;
  }

  async function getLinkMateLink(id: number) {
    const res = await http.get<StrapiResponseDTO<StrapiDTO<LinkMateLink>>>(`/api/profile-links/${id}`, getHeaders());
    return res.data;
  }

  async function getLinkMateLinkByMainType(linkMatateId: number, searchType: LinkMateLink['mainType']) {
    const query = qs.stringify(
      {
        filters: {
          profileTree: {
            id: {
              $eq: linkMatateId,
            },
          },
          mainType: {
            $eq: searchType,
          },
        },
        sort: ['order:asc'],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    const res = await http.get<StrapiResponseDTO<StrapiDTO<LinkMateLink>[]>>(`/api/profile-links?${query}`, getHeaders());
    return res.data.data[0];
  }
  async function getAllLinkMateLinkByMainType(linkMateId: number, searchType: LinkMateLink['mainType'][]) {
    const query = qs.stringify(
      {
        filters: {
          mainType: {
            $eq: searchType,
          },
        },
        sort: ['order:asc'],
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    return await getAllLinkMateLink(linkMateId, query);
  }

  async function getAllLinkMateLinksWithoutMainType(linkMateId: number, searchType: LinkMateLink['mainType'][]) {
    const query = qs.stringify(
      {
        filters: {
          mainType: {
            $notIn: searchType,
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    return await getAllLinkMateLink(linkMateId, query);
  }

  async function getLinkMateLinkByMainTypeAndSubType(
    linkMateId: number,
    mainTypes: LinkMateLink['mainType'][],
    subTypes: LinkMateLink['subType'][]
  ) {
    const query = qs.stringify({
      filters: {
        mainType: {
          $in: mainTypes,
        },
        subType: {
          $in: subTypes,
        },
      },
    });
    return await getAllLinkMateLink(linkMateId, query);
  }
  async function getAllLinkMateLink(linkMateId: number, query?: string) {
    let start = 0;
    let limit = 25;
    let total = 0;
    let links: StrapiDTO<LinkMateLink>[] = [];
    let partialResult: StrapiResponseDTO<StrapiDTO<LinkMateLink>[]> | null = null;
    do {
      partialResult = (await getPaginatedLinkMateLinks(linkMateId, start, limit, query)).data;
      start += partialResult.data.length;
      limit += partialResult.meta?.pagination?.limit || 0;
      total = partialResult.meta?.pagination?.total || 0;
      links = links.concat(partialResult.data);
    } while (links.length < total);
    return links;
  }

  async function getPaginatedLinkMateLinks(linkMateId: number, start: number, limit: number, additionalQuery?: string) {
    let query = qs.stringify(
      {
        filters: {
          profileTree: {
            id: {
              $eq: linkMateId,
            },
          },
        },
        sort: ['order:asc'],
        pagination: {
          start: start,
          limit: limit,
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    if (additionalQuery) {
      query = query.concat('&').concat(additionalQuery);
    }

    const res = await http.get<StrapiResponseDTO<StrapiDTO<LinkMateLink>[]>>(`/api/profile-links?${query}`, getHeaders());
    return res;
  }

  async function updateLinkMateLink(id: number, payload: Partial<LinkMateLink>) {
    if (shouldLinkBeSanitized(payload)) {
      payload.value = trimAndSanitizeLink(payload.value) || payload.value;
    }
    const res = await http.put<StrapiResponseDTO<StrapiDTO<LinkMateLink>>>(
      `/api/profile-links/${id}`,
      { data: { ...payload } },
      getHeaders()
    );
    return res.data;
  }

  async function deleteLinkMateLink(id: number) {
    const res = await http.delete<StrapiResponseDTO<StrapiDTO<LinkMateLink>>>(`/api/profile-links/${id}`, getHeaders());
    return res.data;
  }

  async function getLinkMateLinkHealthVideo() {
    const query = qs.stringify(
      {
        filters: {
          subType: {
            $eq: 'healthVideo',
          },
          profileTree: {
            id: {
              $eq: userStore.loggedInInfo?.user?.profileTree?.id,
            },
          },
        },
        sort: ['createdAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    const res = await http.get<StrapiResponseDTO<Array<StrapiDTO<LinkMateLink>>>>(`/api/profile-links?${query}`, getHeaders());
    return res.data;
  }
  async function getLinkMateLinkIncomeVideo() {
    const query = qs.stringify(
      {
        filters: {
          subType: {
            $eq: 'interestVideo',
          },
          profileTree: {
            id: {
              $eq: userStore.loggedInInfo?.user?.profileTree?.id,
            },
          },
        },
        sort: ['createdAt:desc'],
      },
      { encodeValuesOnly: true }
    );
    const res = await http.get<StrapiResponseDTO<Array<StrapiDTO<LinkMateLink>>>>(`/api/profile-links?${query}`, getHeaders());
    return res.data;
  }

  function getSocialLinkVisibleName(subType: string) {
    if (subType === 'number') {
      return `lead_attribute.sms`;
    }
    return socialOptions.find(option => option.type === subType)?.visibleName || subType;
  }

  return {
    createLinkMateLink,
    createBatchLinkMateLinks,
    updateLinkMateLink,
    deleteLinkMateLink,
    getLinkMateLink,
    getLinkMateLinkByMainType,
    getAllLinkMateLinkByMainType,
    getAllLinkMateLinksWithoutMainType,
    getAllLinkMateLink,
    getLinkMateLinkHealthVideo,
    getLinkMateLinkIncomeVideo,
    getSocialLinkVisibleName,
    getLinkMateLinkByMainTypeAndSubType,
    createInitialDefaultLinkMateLinks,
  };
}
