import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';
import { SaleStep } from '@/services/useKanbanLists';

export interface StepButton {
  text: string;
  action: () => void;
  isVisible?: boolean;
}

export interface SaleSubStep {
  substep: number;
  message: string;
  buttons?: StepButton[];
  check?: () => Promise<void>;
}

interface SSPState {
  leads: Record<
    number,
    {
      step: SaleStep;
      substep: number;
      lastUpdate: Date;
      loopCounter: number;
    }
  >;
}

export const useSSPStore = defineStore(
  'sspStore',
  () => {
    const state = reactive<SSPState>({
      leads: {},
    });

    const setLeadSSP = ({
      leadId,
      step,
      substep,
      loopCounter,
    }: {
      leadId: number;
      step?: SaleStep;
      substep: number;
      loopCounter?: number;
    }) => {
      const effectiveStep = step || state.leads[leadId]?.step;

      state.leads[leadId] = {
        ...state.leads[leadId],
        step: effectiveStep,
        substep: substep || 0,
        lastUpdate: new Date(),
        loopCounter: loopCounter || state.leads[leadId]?.loopCounter || 0,
      };
    };

    const clearLeadSSP = (leadId: number) => {
      if (state.leads[leadId]) {
        delete state.leads[leadId];
      }
    };

    const bulkClearLeadSSP = (leadIds: number[]) => {
      if (leadIds.length === 0) return;

      if (leadIds.length === 1) {
        clearLeadSSP(leadIds[0]);
        return;
      }

      const updatedLeads = { ...state.leads };

      leadIds.forEach(leadId => {
        delete updatedLeads[leadId];
      });

      state.leads = updatedLeads;
    };

    const increaseLoopCounter = (leadId: number) => {
      if (!state.leads[leadId]) return;

      state.leads[leadId] = {
        ...state.leads[leadId],
        loopCounter: (state.leads[leadId].loopCounter || 0) + 1,
      };
    };

    const resetLoopCounter = (leadId: number) => {
      if (state.leads[leadId]) {
        state.leads[leadId].loopCounter = 0;
      }
    };

    const isLeadJourneyStarted = (leadId: number) => {
      return !!state.leads[leadId];
    };

    return {
      ...toRefs(state),
      isLeadJourneyStarted,
      setLeadSSP,
      clearLeadSSP,
      bulkClearLeadSSP,
      increaseLoopCounter,
      resetLoopCounter,
    };
  },
  {
    persist: true,
  }
);
