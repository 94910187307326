import { CountryCode, phoneCodes } from '@/stores/constants';
import { countryCodeToNameRecord } from '@/stores/constants';
import { DMOColors } from './dmoService';
import { AccountLanguage } from './useLanguageService';
import { LeadMagnetColor } from './leadMagnetService';
export interface FormatOption {
  locale?: AccountLanguage;
  formatString?: string;
}

export const months = [
  'months.january',
  'months.february',
  'months.march',
  'months.april',
  'months.may',
  'months.june',
  'months.july',
  'months.august',
  'months.september',
  'months.october',
  'months.november',
  'months.december',
];

export function useUtilsService() {
  const trimPhoneNumber = (cellPhone?: string) => {
    if (cellPhone) {
      return cellPhone.startsWith('+') ? `+${cellPhone.replace(/[^\d]/g, '')}` : cellPhone.replace(/[^\d]/g, '');
    }
    return cellPhone;
  };
  const trimAndSanitizeLink = (link?: string, addTrailingSlash = false) => {
    if (!link) {
      return link;
    }
    let tmp = link.trim();
    if (tmp.includes('://')) {
      tmp = tmp.replace(tmp.substring(0, tmp.indexOf('://')), 'https');
    } else {
      tmp = 'https://' + tmp;
    }
    if (addTrailingSlash && !tmp.endsWith('/')) {
      tmp += '/';
    }
    return tmp;
  };

  const trimAndSanitizeWithPrefix = (link?: string, prefix?: string) => {
    if (!prefix) {
      return undefined;
    }
    if (!link) {
      return link;
    }
    const trimmedLink = link.trim();
    if (trimmedLink === '') {
      return '';
    }

    // Remove any protocol or www prefix from the link
    const cleanLink = trimmedLink.replace(/^(https?:\/\/|www\.)/, '');

    // Get the domain part of the prefix (without protocol and trailing slash)
    const prefixDomain = prefix.replace(/^https?:\/\//, '').replace(/\/$/, '');

    // If the link contains the domain, extract everything after it
    if (cleanLink.includes(prefixDomain)) {
      const parts = cleanLink.split(prefixDomain);
      const path = parts[1] || '';
      return path.replace(/^\//, '') || '';
    }

    // If no domain found, treat the entire link as the path
    return cleanLink;
  };

  const alphabeticalOrder = (el1: string, el2: string) => {
    const nameA = el1.toUpperCase();
    const nameB = el2.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const getCurrencyFromTimeZone = () => {
    let currency = 'usd';
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZoneName.includes('Europe')) {
      currency = 'eur';
    }
    return currency;
  };

  const euOrUs = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone.startsWith('Europe') ? 'EU' : 'US';
  };

  const computeColor = (color?: LeadMagnetColor | DMOColors | string) => {
    switch (color) {
      case 'teal':
        return { text: 'text-teal-500', bgLight: 'bg-teal-500/50', bg: 'bg-teal-500' };
      case 'green':
        return { text: 'text-green-600', bgLight: 'bg-green-600/50', bg: 'bg-green-600' };
      case 'blue':
        return { text: 'text-blue-500', bgLight: 'bg-blue-500/50', bg: 'bg-blue-500' };
      case 'purple':
        return { text: 'text-purple-500', bgLight: 'bg-purple-500/50', bg: 'bg-purple-500' };
      case 'gray':
        return { text: 'text-gray-500', bgLight: 'bg-gray-500/50', bg: 'bg-gray-500' };
      case 'orange':
        return { text: 'text-orange-500', bgLight: 'bg-orange-500/50', bg: 'bg-orange-500' };
      case 'yellow':
        return { text: 'text-yellow-500', bgLight: 'bg-yellow-500/50', bg: 'bg-yellow-500' };
      default:
        return { text: 'text-blue-500', bgLight: 'bg-blue-500/50', bg: 'bg-blue-500' };
    }
  };

  function capitalizeFirstLetter(str: string): string;
  function capitalizeFirstLetter(str: undefined): undefined;
  function capitalizeFirstLetter(str: null): undefined;
  function capitalizeFirstLetter(str: undefined | string): undefined | string;
  function capitalizeFirstLetter(str: undefined | string | null): undefined | string;
  function capitalizeFirstLetter(str: string | null | undefined) {
    if (!str) return;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const getInitialsInCapitalLetter = (name?: string) => {
    if (!name) return 'NA';
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const validateEmail = (email: string) => {
    try {
      const emailRegEx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegEx.test(email.toLowerCase());
    } catch (err) {
      return false;
    }
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    try {
      const phoneRegEx = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/;
      return phoneRegEx.test(phoneNumber);
    } catch (err) {
      return false;
    }
  };

  function retryWithExponentialBackoff<T>(func: () => Promise<T>, maxAttempts = 1, baseDelay = 1000, condition: (result: T) => boolean) {
    let attempts = 0;

    const executeWithRetry = async () => {
      const result = await func();
      if (condition(result)) {
        return result;
      }

      if (attempts < maxAttempts) {
        const delay = baseDelay * Math.pow(2, attempts);
        console.info(`Retrying in ${delay}ms...`);
        await new Promise(resolve => setTimeout(resolve, delay));
        attempts++;
        return executeWithRetry();
      } else {
        return result;
      }
    };

    return executeWithRetry();
  }

  const handlePasteText = (event: ClipboardEvent) => {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text');
    return pastedText;
  };

  const getCountryNameFromCode = (code: string | undefined) => {
    if (!code) return undefined;
    if (Object.keys(countryCodeToNameRecord).includes(code)) {
      return countryCodeToNameRecord[code as CountryCode];
    }
    return undefined;
  };

  const getCountryCodeFromName = (countryName: string) => {
    const countryObject = phoneCodes.find(c => c.label.toLowerCase() === countryName.toLowerCase());
    if (countryObject) return getCountryNameFromCode(countryObject.abbreviation);
    return undefined;
  };

  return {
    trimPhoneNumber,
    trimAndSanitizeLink,
    alphabeticalOrder,
    getCurrencyFromTimeZone,
    euOrUs,
    computeColor,
    getInitialsInCapitalLetter,
    capitalizeFirstLetter,
    validateEmail,
    validatePhoneNumber,
    retryWithExponentialBackoff,
    handlePasteText,
    getCountryNameFromCode,
    getCountryCodeFromName,
    trimAndSanitizeWithPrefix,
  };
}
