<script setup lang="ts">
import { useUserStore } from './stores/userStore';
import { computed, inject } from 'vue';
import { FormKitConfig } from '@formkit/core';
import { useUserflow } from './services/useUserflow';
import { useRoute } from 'vue-router';
const userStore = useUserStore();
const userflow = useUserflow();
const route = useRoute();

const isLoading = computed(() => route.matched.length === 0);

const formkitConfig = inject<FormKitConfig>(Symbol.for('FormKitConfig'));

userflow.init();

// A subscribe is needed because formkitConfig is only accessible inside a vue file
// TODO understand if we need to check if formkit by default take the browser language
userStore.$subscribe((mutation, state) => {
  const currentLanguage = state.loggedInInfo?.user?.accountLanguage;
  if (formkitConfig && currentLanguage && currentLanguage != formkitConfig.locale) {
    if (currentLanguage === 'mx') formkitConfig.locale = 'es';
    else formkitConfig.locale = currentLanguage;
  }
});
</script>

<template>
  <RouterView />
  <div v-if="isLoading" class="fixed inset-0 z-50 bg-white flex items-center justify-center">
    <img src="@/assets/logo/logo_no_text.svg" alt="SAGA" class="w-[204px] h-[204px]" />
  </div>
  <Teleport to="body">
    <div id="tsparticles" class="relative z-[70] pointer-events-none"></div>
  </Teleport>
</template>
