import { ref, Ref, onMounted, onUnmounted, computed } from 'vue';

interface ScreenSizes {
  width: Ref<number>;
  height: Ref<number>;
  isMobile: Ref<boolean>;
  isXxs: Ref<boolean>;
  isXs: Ref<boolean>;
  isSm: Ref<boolean>;
  isMd: Ref<boolean>;
  isLg: Ref<boolean>;
  isLgIncluded: Ref<boolean>;
  isXl: Ref<boolean>;
  is2Xl: Ref<boolean>;
  is3Xl: Ref<boolean>;
  isMdIncluded: Ref<boolean>;
  isXlIncluded: Ref<boolean>;
  is2XlIncluded: Ref<boolean>;
}
export function useScreenSizes(): ScreenSizes {
  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  const isMobile = computed(() => width.value < 1024);
  const isXxs = computed(() => width.value <= 320);
  const isXs = computed(() => width.value <= 375);
  const isSm = computed(() => width.value <= 640);
  const isMd = computed(() => width.value > 640 && width.value <= 768);
  const isMdIncluded = computed(() => width.value <= 768);
  const isLg = computed(() => width.value > 768 && width.value <= 1024);
  const isLgIncluded = computed(() => isMobile.value);
  const isXl = computed(() => width.value > 1024 && width.value <= 1280);
  const isXlIncluded = computed(() => width.value <= 1280);
  const is2Xl = computed(() => width.value > 1280 && width.value <= 1536);
  const is2XlIncluded = computed(() => width.value <= 1536);
  const is3Xl = computed(() => width.value > 1536);

  function update() {
    width.value = window.document.body.getBoundingClientRect().width;
    height.value = window.document.body.getBoundingClientRect().height;
  }

  onMounted(() => window.addEventListener('resize', update));
  onUnmounted(() => window.removeEventListener('resize', update));

  return {
    width,
    height,
    isMobile,
    isXxs,
    isXs,
    isSm,
    isMd,
    isLg,
    isLgIncluded,
    isXl,
    is2Xl,
    is3Xl,
    isMdIncluded,
    isXlIncluded,
    is2XlIncluded,
  };
}
