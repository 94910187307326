import { ref } from 'vue';
import http, { useAxiosUtils } from './axiosClient';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Tenant, useTenantService } from './tenantService';
import { useHandleError } from './logErrors';
import { useUtilsService } from './utilsService';
import { useUserStore } from '@/stores/userStore';
import { envs } from '@/env';

export type RecurringInterval = 'month' | 'year';

const features = [
  'dmo',
  'email_broadcast',
  'email_list',
  'see_all_contacts',
  'share_videos_in_library',
  '100_email_limit',
  '1000_email_limit',
  '5000_email_limit',
  '10000_email_limit',
  '25000_email_limit',
  'see_all_news',
  'saga_standard',
  'personalized_lead_magnet',
  'personalized_form',
  'lead_resources',
  'personalized_library_category',
  'saga_lead_agent',
] as const;
export type Features = (typeof features)[number];

export type Plan = {
  freeTrialDays: number;
  prices: Price[];
};

export type Price = {
  priceId: string;
  price: number;
  currency: string;
  lookupKey: string | null;
  recurring: {
    interval: RecurringInterval;
    intervalCount: number;
  };
  features: string[];
};

export type SubscriptionsStatus =
  | 'no_subscription'
  | 'trialing'
  | 'trial_expired'
  | 'subscribing'
  | 'cancelling'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'expired';

export type SubscriptionsStatusDTO = {
  isValid: boolean;
  status: SubscriptionsStatus;
  freeTrialMillisecondsLeft: number;
  features: string[];
  currentPriceId: string | null;
};

export type CheckoutSessionStatus = 'open' | 'expired' | 'complete';

export type CheckoutSessionStatusDTO = {
  status: CheckoutSessionStatus;
  customerId: string;
};

const plans = ref<Partial<Record<Tenant, Plan>>>({});
let stripePromise = ref<Promise<Stripe> | null>(null);

export type CustomerPortalFlowDataType = 'payment_method_update' | 'subscription_update_confirm' | 'subscription_update';

export function useStripeService() {
  const { getHeaders } = useAxiosUtils();
  const { getTenantName } = useTenantService();
  const { throwError } = useHandleError();
  const { getCurrencyFromTimeZone, retryWithExponentialBackoff } = useUtilsService();
  const userStore = useUserStore();

  const getStripe = async () => {
    if (stripePromise.value) {
      return await stripePromise.value;
    }

    try {
      stripePromise.value = doLoadStripe();
      return await stripePromise.value;
    } catch (error) {
      stripePromise.value = null;
      throw error;
    }
  };

  const doLoadStripe = async () => {
    const loadedStripe = await loadStripe(envs.VITE_STRIPE_PUBLISHABLE_KEY);
    if (!loadedStripe) {
      throw new Error('Failed to load stripe - loadStripe');
    }
    return loadedStripe;
  };

  const getPlanByTenant = async () => {
    const currency = getCurrencyFromTimeZone();
    const tenantName = getTenantName();
    if (!tenantName) {
      const error = new Error('Tenant not found');
      throwError(error);
      throw error;
    }
    const plan = plans.value[tenantName];
    if (plan) return plan;

    const res = await http.get<Plan>(`/api/stripe/${tenantName}/plans?currency=${currency}`);
    plans.value[tenantName] = res.data;
    return res.data;
  };

  const createCheckoutSession = async (priceId: string, redirectPath: string) => {
    const currency = getCurrencyFromTimeZone();
    const res = await http.post<{ id: string; clientSecret: string }>(
      `/api/stripe/checkout-session`,
      { priceId, redirectPath, currency },
      getHeaders()
    );
    return res.data;
  };

  const getSessionStatus = async (sessionId: string) => {
    const res = await http.get<CheckoutSessionStatusDTO>(`/api/stripe/checkout-session/${sessionId}`, getHeaders());
    return res.data;
  };

  const fulfillCheckout = async (checkoutSessionId: string) => {
    await http.post<void>(`/api/stripe/fulfill-checkout`, { checkoutSessionId }, getHeaders());
  };

  const getSubscriptionStatus = async () => {
    const res = await http.get<SubscriptionsStatusDTO>(`/api/stripe/subscription-status`, getHeaders());
    return res.data;
  };

  const checkIfUserHasValidSubscriptionAndFeatureAccess = async (userId: number, feature: Features) => {
    try {
      const res = await http.get<{ hasAccess: boolean }>(`/api/stripe/feature-check/${userId}/${feature}`);
      return res.data.hasAccess;
    } catch (error) {
      throwError(error);
      return false;
    }
  };

  const createCustomerPortalSession = async (flowDataType?: CustomerPortalFlowDataType, returnUrlPath?: string, priceId?: string) => {
    const res = await http.post<{ url: string }>(
      `/api/stripe/customer-portal`,
      {
        flowDataType,
        returnUrlPath,
        priceId,
      },
      getHeaders()
    );
    return res.data;
  };

  const isValidFeature = (feature: string): feature is Features => {
    return features.includes(feature as Features);
  };

  async function handleLoggedInUserSubscription(withRetry?: boolean): Promise<void> {
    let responseSubscriptionStatus: SubscriptionsStatusDTO | null = null;
    if (withRetry) {
      responseSubscriptionStatus = await retryWithExponentialBackoff(
        getSubscriptionStatus,
        3,
        1000,
        (result: SubscriptionsStatusDTO) => result.isValid === true
      );
    } else {
      responseSubscriptionStatus = await getSubscriptionStatus();
    }
    userStore.updateSubscriptionStatus({
      features: responseSubscriptionStatus.features.filter(isValidFeature),
      isUserSubscribed: responseSubscriptionStatus.isValid,
      freeTrialDaysLeft: Math.round(responseSubscriptionStatus.freeTrialMillisecondsLeft / (1000 * 60 * 60 * 24)),
      status: responseSubscriptionStatus.status,
      currentPriceId: responseSubscriptionStatus.currentPriceId,
    });
  }

  async function startFreeTrial() {
    const res = await http.post<CheckoutSessionStatusDTO>(`/api/stripe/start-free-trial`, {}, getHeaders());
    return res.data;
  }

  return {
    getStripe,
    getPlanByTenant,
    getSessionStatus,
    fulfillCheckout,
    getSubscriptionStatus,
    createCustomerPortalSession,
    createCheckoutSession,
    handleLoggedInUserSubscription,
    checkIfUserHasValidSubscriptionAndFeatureAccess,
    startFreeTrial,
  };
}
