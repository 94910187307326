export default `<svg viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.68116 0H5.32366C6.2525 0 7.00482 0.745412 7.00482 1.66572V5.2748C7.00482 6.19511 7.75714 6.94052 8.68598 6.94052H12.3285C13.2573 6.94052 14.0096 7.68593 14.0096 8.60624V12.2153C14.0096 13.1356 13.2573 13.881 12.3285 13.881H8.68598C7.75714 13.881 7.00482 13.1356 7.00482 12.2153V8.60624C7.00482 7.68593 6.2525 6.94052 5.32366 6.94052H1.68116C0.752318 6.94052 0 6.19511 0 5.2748V1.66572C0 0.745412 0.752318 0 1.68116 0Z" fill="url(#paint0_linear_3263_18592)"/>
<path d="M5.60386 17.3513C5.60386 16.2014 4.66301 15.2691 3.50241 15.2691C2.34181 15.2691 1.40096 16.2014 1.40096 17.3513C1.40096 18.5012 2.34181 19.4335 3.50241 19.4335C4.66301 19.4335 5.60386 18.5012 5.60386 17.3513Z" fill="url(#paint1_linear_3263_18592)"/>
<defs>
<linearGradient id="paint0_linear_3263_18592" x1="4.05855" y1="2.02927" x2="39.0635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
<linearGradient id="paint1_linear_3263_18592" x1="4.05855" y1="2.02927" x2="39.0635" y2="20.2928" gradientUnits="userSpaceOnUse">
<stop stop-color="#4F46E5"/>
<stop offset="1" stop-color="#2C277F"/>
</linearGradient>
</defs>
</svg>

`;
