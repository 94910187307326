import { RouteWithName } from '@/router';

export const routes: RouteWithName[] = [
  {
    path: `/link/:linkName`,
    name: `LinkMateLayout`,
    component: () => import('../../pages/linkMate/LinkMateLayout.vue'),
    meta: {
      requiresAuth: false,
      isFullPage: true,
    },
    children: [
      {
        path: 'opportunity',
        name: 'BusinessInformationFlow',
        redirect: to => ({ path: `link/${to.params.linkName}/info-page/info/business` }),
      },
      {
        path: 'info',
        name: 'ProductInformationFlow',
        redirect: to => ({ path: `link/${to.params.linkName}/info-page/info/product` }),
      },
    ],
  },
];
