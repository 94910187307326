import { TenantConfig } from '@/router/tenantConfig';

export const config: TenantConfig = {
  infoPageIdsToShow: [],
  UNICORN_PAGE_URL: '',
  postHogDashboardLink: 'https://us.posthog.com/shared/ISeSelO9KQiWzT725TmtBZWYrmAXHA',
  customCsvImportService: [],
  deprecatingOldInfoPages: false,
  leadRadarLink: '',
  hideLastOrder: true,
  hideShopLink: false,
  hideInfoPageAnalytic: false,
  hideLeadMagnetAnalytic: false,
  hideLeaderboard: false,
  showRegistrationLink: false,
  showBundles: [],
  menuItemsToHide: ['InfoPageLinkPage'],
  sourcesToShow: ['Manually Created', 'CONTACT_ME_FORM', 'INFORMATION_FLOW', 'LEAD_MAGNET', 'CSV'],
  membershipsToShow: ['Lead', 'Customer', 'Promoter', 'Lost'],
  interestsToShow: ['business', 'product'],
  hideColdInfoPage: false,
  showSSP: true,
  isB2C: false,
  showRequestedLeadsInSchedulerComponent: false,
  hideInfoPages: false,
};
