import { useUserStore } from '@/stores/userStore';
import qs from 'qs';
import http, { StrapiDTO, StrapiResponseDTO, useAxiosUtils } from './axiosClient';
import useI18nUtils from './useI18nUtils';
import { tenantsArray } from '@/env';
import { useHandleError } from './logErrors';
import { AccountLanguage } from './useLanguageService';
import { toast } from '@/components/subcomponents/toast/toast';
import { i18n } from '@/plugins/i18nPlugin';
import { AdminResourceName } from '@/router';
import { computed, ref, Ref } from 'vue';
import { TenantConfig, tenantConfigMap } from '@/router/tenantConfig';
export interface TenantDTO {
  tenant: Tenant;
  createdAt?: Date;
  updatedAt?: Date;
}
export type TenantWithId = TenantDTO & { id: number };

export type Tenant = (typeof tenantsArray)[number];

const currentTenant: Ref<Tenant | undefined> = ref();

export function useTenantService() {
  const computedTenantName = computed(() => {
    return currentTenant.value || getTenantFromRoute();
  });

  const isValidTenant = (tenant?: string): tenant is Tenant => {
    const availableTenants: readonly string[] = tenantsArray;
    return !!tenant && availableTenants.includes(tenant);
  };

  const getTenantFromRoute = () => {
    const parts = window.location.pathname.split('/').filter(part => part);
    const tenant = parts[0];
    if (!isValidTenant(tenant)) return;
    return tenant;
  };

  const getTenantName = () => {
    return currentTenant.value || getTenantFromRoute();
  };

  const setTenant = (tenant: string) => {
    if (!isValidTenant(tenant)) throw new Error('Invalid tenant');
    currentTenant.value = tenant;
    useI18nUtils().setLanguage(useUserStore().loggedInInfo?.user?.accountLanguage, tenant);
  };

  const isCorrectTenant = (tenantName?: string) => {
    return tenantName === getTenantName();
  };

  const getTenantId = () => {
    const userStore = useUserStore();
    if (!userStore.loggedInInfo?.user?.tenant) {
      useHandleError().throwError('Tenant not found');
      throw new Error('Tenant not found');
    }
    return userStore.loggedInInfo?.user?.tenant.id;
  };

  const getTenantFromApi = async (tenant: Tenant) => {
    const query = qs.stringify(
      {
        filters: {
          tenant: { $eq: tenant },
        },
      },
      { encodeValuesOnly: true }
    );
    try {
      const resp = await http.get<StrapiResponseDTO<StrapiDTO<TenantDTO>[]>>(`api/tenants?${query}`);
      if (!resp.data.data.length) return;
      return resp.data.data[0];
    } catch (error) {
      return;
    }
  };

  const getTenants = async () => {
    try {
      const resp = await http.get<StrapiResponseDTO<StrapiDTO<TenantDTO>[]>>(`api/tenants`);
      return resp.data.data;
    } catch (error) {
      return [];
    }
  };

  const checkoutAdminRouteOnTenant = async (resourceName: AdminResourceName, locale?: AccountLanguage) => {
    const { getHeaders } = useAxiosUtils();
    try {
      const resp = await http.put<{ isResourceAvailable: boolean }>(
        `api/logged-in-admin/checkoutResource/${getTenantId()}/${locale}/${resourceName}`,
        {},
        getHeaders()
      );
      return resp.data.isResourceAvailable;
    } catch (error) {
      const errors = useHandleError().handleError(error);
      if (errors) {
        const forbiddenError = JSON.parse(errors[0]);
        toast(i18n.global.t(forbiddenError.message_key, { admin: forbiddenError.user }), { type: 'err' });
      }
      return false;
    }
  };

  const getTenantConfigProperty = <T extends keyof TenantConfig>({
    propertyName,
    defaultValue,
  }: {
    propertyName: T;
    defaultValue: TenantConfig[T];
  }) => {
    const tenant = computedTenantName.value;
    if (!tenant) return defaultValue;
    return tenantConfigMap[tenant].config[propertyName] ?? defaultValue;
  };

  return {
    isCorrectTenant,
    getTenantName,
    setTenant,
    getTenantFromRoute,
    getTenantFromApi,
    getTenantId,
    getTenants,
    checkoutAdminRouteOnTenant,
    computedTenantName,
    getTenantConfigProperty,
  };
}
