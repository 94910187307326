import { RouteWithName } from '@/router';
import { AsyncComponentLoader } from '@/router/useRouterHelpers';

export const routes: RouteWithName[] = [
  {
    path: '/',
    name: 'MainLayout',
    component: () => import('../shared/MainLayoutView.vue'),
    children: [
      {
        path: 'register',
        name: 'RegisterPage',
        component: AsyncComponentLoader(() => import('./RegisterPageView.vue')),
        meta: { requiresAuth: false },
      },
    ],
  },
];
