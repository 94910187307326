import posthog from 'posthog-js';
import { UserDTO } from './userDataService';

export type CustomPosthogEvent =
  | 'dmo_step_completed'
  | 'scheduled_follow_up'
  | 'linkmate_name_updated'
  | 'csv_contacts_imported'
  | 'leads_page_filter_button_clicked'
  | 'leads_page_filter_selected'
  | 'leads_page_view_changed';

type EventProperties = {
  dmo_step_completed: {
    stepId: string;
    stepName: string;
  };
  scheduled_follow_up: {
    leadId: number;
    followUpDate: string;
  };
  linkmate_name_updated: {
    linkMateId: number;
    oldName: string;
    newName: string;
  };
  csv_contacts_imported: {
    count: number;
    newLeads: number;
    updatedLeads: number;
    usersToCustomers: number;
    usersToPromoters: number;
    usersToSmartship: number;
    customersToSmartship: number;
    smartshipToCustomers: number;
    customersToAffiliates: number;
    affiliatesToCustomers: number;
  };
  leads_page_filter_button_clicked: {
    filter_type: string;
    page: string;
  };
  leads_page_filter_selected: {
    filter_type: string;
    filter_value: string;
    page: string;
    date_from?: string;
    date_to?: string;
  };
  leads_page_view_changed: {
    from_view: string;
    to_view: string;
    page: string;
  };
};

export function usePosthog() {
  const identifyPosthog = (user: UserDTO) => {
    posthog.identify(user.id.toString(), {
      fullName: `${user.firstName} ${user.lastName}`,
      tenantName: user.tenant.tenant,
      language: user.accountLanguage || 'unknown',
      email: user.email,
    });
  };

  const updateUserProperties = (properties: Record<string, any>) => {
    posthog.capture('$set', {
      $set: properties,
    });
  };

  const capture = <T extends CustomPosthogEvent>(event: T, properties: EventProperties[T]) => {
    try {
      posthog.capture(event, properties);
    } catch (error) {
      console.error(`Failed to capture PostHog event: ${event}`, error);
    }
  };

  return {
    identifyPosthog,
    updateUserProperties,
    capture,
  };
}
