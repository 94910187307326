import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { useUserService } from '../services/userDataService';
import { useUserStore } from '../stores/userStore';
import { envs } from '../env';
import { TenantDTO, useTenantService } from '@/services/tenantService';
import { addPrefixToRoutes, useRouterHelpers } from './useRouterHelpers';
import { routes as baseRoutes } from '../views/shared/routes';
import { AccountLanguage } from '@/services/useLanguageService';
import { tenantConfig } from './tenantConfig';
import { cloneDeep } from 'lodash';

export const availableRoutesArray = [
  'MainLayout',
  'LandingPage',
  'B2CLandingPage',
  'DashboardHome',
  'ViewAllLeads',
  'LeadsPage',
  'ManageSubscription',
  'SagaMessagingPage',
  'ImportContacts',
  'HostLogin',
  'SupportCenter',
  'ForgotPassword',
  'RegisterPage',
  'HelpCenter',
  'ChatBotRedirect',
  'LibraryPage',
  'LibraryCategoryContentPage',
  'PageNotFound',
  'FastMessagingPage',
  'MassMessagingPage',
  'LinkMateLeadPage',
  'FollowUpPage',
  'LinkMateLayout',
  'LinkMateOnboarding',
  'LinkMatePage',
  'OnBoardingPage',
  'DMOPage',
  'ProductInformationFlow',
  'ProductInfoPageQuestionaire',
  'BusinessInfoPageQuestionaire',
  'BusinessInformationFlow',
  'KetoinfoSolutionPage',
  'OpportunitySolutionPage',
  'UserflowHelp',
  'ProspectingToolsSettingsPage',
  'UnicornPage',
  'BroadcastPage',
  'EmailListsPage',
  'InfoPageLinkPage',
  'BusinessInfoPageLinkPage',
  'ProductInfoPageLinkPage',
  'LeadMagnetLinkPage',
  'WelcomePage',
  'StatisticsPage',
  'NoContactsPage',
  'StripeCheckoutPage',
  'ExternalVideoPage',
  'ExternalResourcePage',
  'PricingPage',
  'ProductInformationFlowCollagen',
  'ProductInformationFlowNutrition',
  'BusinessInformationFlowWarm',
  'BusinessInformationFlowCold',
  'ExternalInfoPageCold',
  'ExternalInfoPageWarm',
  'ExternalLeadMagnetPage',
  'LeaderBoardPage',
  'AnalyticsPage',
  'AdminWelcomePage',
  'ExternalLeadMagnetPersonalizedPage',
  'ExternalLeadMagnetPageRedirect',
] as const;

export type AvailableRoutes = (typeof availableRoutesArray)[number];

export type AdminResourceName =
  | 'adminWelcome'
  | 'dashboard'
  | 'library'
  | 'onboarding'
  | 'dmo'
  | 'businessInfoPage'
  | 'productInfoPage'
  | 'statistics'
  | 'leadMagnets';

export type RouteWithName = RouteRecordRaw & { name?: AvailableRoutes; children?: RouteWithName[] };
declare module 'vue-router' {
  interface RouteMeta {
    // must be declared by every route
    requiresAuth: boolean;
    isFullPage?: boolean;
    hasAdminPage?: boolean;
    resourceName?: AdminResourceName;
    changeBackgroundColor?: boolean;
  }
}

const VITE_LINKMATE_PREFIX = envs.VITE_LINKMATE_PREFIX;

const mergeRoutes = (baseRoutes: RouteRecordRaw[], overrides: RouteRecordRaw[]): RouteRecordRaw[] => {
  const routeMap = new Map<string, RouteRecordRaw>();

  const addRoutesToMap = (routes: RouteRecordRaw[]) => {
    routes.forEach(route => {
      if (!route.name) return;
      const existingRoute = routeMap.get(route.name.toString());
      if (!existingRoute) {
        routeMap.set(route.name.toString(), { ...route });
        return;
      }
      if (existingRoute && route.children) {
        existingRoute.children = mergeRoutes(route.children, existingRoute.children || []);
      }
    });
  };

  addRoutesToMap(overrides);
  addRoutesToMap(baseRoutes);

  return Array.from(routeMap.values());
};

const generateRoutes = () => {
  let routesWithPrefix: RouteRecordRaw[] = [];
  let routesWithoutPrefix: RouteRecordRaw[] = [];
  tenantConfig.forEach(tenant => {
    const baseRoutesWithPrefix = addPrefixToRoutes(cloneDeep(baseRoutes), tenant.prefix);
    const customRoutesWithPrefix = addPrefixToRoutes(cloneDeep(tenant.customRoutes), tenant.prefix);
    const mergedRoutesWithPrefix = mergeRoutes(baseRoutesWithPrefix, customRoutesWithPrefix);
    routesWithPrefix = routesWithPrefix.concat(mergedRoutesWithPrefix);
  });

  routesWithoutPrefix = mergeRoutes(routesWithoutPrefix, baseRoutes);
  return routesWithPrefix.concat(routesWithoutPrefix);
};

const router = createRouter({
  history: createWebHistory(),
  routes: generateRoutes(),
  scrollBehavior(to) {
    if (to.hash) {
      const section = document.querySelector(to.hash);
      section?.scrollIntoView({ behavior: 'smooth' });
    } else window.document.getElementById('main-route')?.scrollTo({ top: 0 });
  },
});

router.beforeEach(async (to, from, next) => {
  const tenantService = useTenantService();
  const { getRouteName, getNewTenantPath } = useRouterHelpers();

  // Redirect to dashboard if user is logged in and tries to access the landing page
  const userStore = useUserStore();
  if (
    (to.name === getRouteName('LandingPage') || to.name === getRouteName('B2CLandingPage')) &&
    userStore.loggedInInfo?.jwt &&
    userStore.loggedInInfo?.user
  ) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }

  // Redirect to linkmate if needed
  if (
    window.location.origin === `${VITE_LINKMATE_PREFIX}` &&
    !to.path.includes('/link') &&
    (to.name != getRouteName('PageNotFound') || from.name === undefined)
  ) {
    const redirectPath = '/link' + to.fullPath;
    next(redirectPath);
    return;
  }

  if (to.name === getRouteName('RegisterPage') && userStore.loggedInInfo?.jwt) {
    next({ name: getRouteName('DashboardHome') });
    return;
  }

  if (useUserService().isUpdateNeeded()) {
    await userStore.updateLoggedInUserInfo();
  }

  // Redirect to correct tenant if needed
  const tenant = await computeTenant(to, userStore.loggedInInfo?.user?.tenant);
  if (tenant && !tenantService.isCorrectTenant(tenant.tenant)) {
    tenantService.setTenant(tenant.tenant);
    const newTenantPath = getNewTenantPath(tenant.tenant, to.fullPath);
    next(newTenantPath);
    return;
  }

  // If public page proceed
  if (!to.meta.requiresAuth || to.name === getRouteName('HelpCenter')) {
    next();
    return;
  }

  // If user is not logged in redirect to login page
  if (!userStore.loggedInInfo?.jwt || !userStore.loggedInInfo?.user) {
    next({ name: getRouteName('HostLogin'), query: { redirect: to.fullPath } });
    return;
  }

  // If user has not completed checkout once redirect to stripe checkout page
  if (
    !userStore.subscriptionsStatus.isUserSubscribed &&
    userStore.subscriptionsStatus.status === 'incomplete_expired' &&
    to.name !== getRouteName('StripeCheckoutPage')
  ) {
    next({ name: getRouteName('StripeCheckoutPage') });
    return;
  }

   if (to.name === getRouteName('WelcomePage') && userStore.loggedInInfo.user.hasBeenWelcomed) {
     next({ name: getRouteName('DashboardHome') });
     return;
   }

  if (to.name === getRouteName('DashboardHome') && !userStore.loggedInInfo.user.hasBeenWelcomed) {
    next({ name: getRouteName('WelcomePage') });
    return;
  }

  if (!userStore.subscriptionsStatus.isUserSubscribed && to.name != getRouteName('ManageSubscription')) {
    next({ name: getRouteName('ManageSubscription') });
    return;
  }
  if (userStore.adminStatus.isAdminModeActive && !to.meta.hasAdminPage) {
    next({ name: getRouteName('AdminWelcomePage') });
    return;
  }
  if (userStore.adminStatus.isAdminModeActive && to.meta.hasAdminPage) {
    const isResourceAvailable = await handleAdminRouteChecker(to, userStore.adminStatus.adminModeLanguage);
    if (!isResourceAvailable) {
      from.name ? next(false) : next({ name: getRouteName('AdminWelcomePage') });
      return;
    }
    next();
    return;
  }
  if (to.name === getRouteName('StripeCheckoutPage') && userStore.subscriptionsStatus.isUserSubscribed) {
    next({ name: getRouteName('WelcomePage') });
    return;
  }
  const isFeatureBlocked = handleFeatureFlags(to, getRouteName);
  if (
    isFeatureBlocked &&
    (userStore.subscriptionsStatus.status === 'subscribing' || userStore.subscriptionsStatus.status === 'cancelling')
  ) {
    next({ name: getRouteName('PricingPage'), query: { redirectPage: to.name?.toString(), suggestedTier: to.query.suggestedTier } });
    return;
  } else if (isFeatureBlocked) {
    next({ name: getRouteName('ManageSubscription'), query: { redirectPage: to.name?.toString(), suggestedTier: to.query.suggestedTier } });
    return;
  }
  if (
    to.name === getRouteName('PricingPage') &&
    userStore.subscriptionsStatus.status !== 'subscribing' &&
    userStore.subscriptionsStatus.status !== 'cancelling'
  ) {
    next({
      name: getRouteName('ManageSubscription'),
      query: { redirectPage: to.query.redirectPage, suggestedTier: to.query.suggestedTier },
    });
    return;
  }
  next();
});

const handleFeatureFlags = (to: RouteLocationNormalized, getRouteName: (name: AvailableRoutes) => string) => {
  const userStore = useUserStore();
  if (to.name === getRouteName('DMOPage') && userStore.isFeatureDisabled('dmo')) {
    return true;
  }
  if (to.name === getRouteName('BroadcastPage') && userStore.isFeatureDisabled('email_broadcast')) {
    return true;
  }
  if (to.name === getRouteName('EmailListsPage') && userStore.isFeatureDisabled('email_list')) {
    return true;
  }
  return false;
};

const computeTenant = async (toRoute: RouteLocationNormalized, loggedInTenant?: TenantDTO) => {
  if (!toRoute.path.includes('/link') || !toRoute.params.linkName || typeof toRoute.params.linkName !== 'string') {
    return loggedInTenant;
  }
  const { getUserFromLinkMateNickName } = useUserService();
  const user = await getUserFromLinkMateNickName(toRoute.params.linkName);
  return user?.tenant;
};

export const handleAdminRouteChecker = async (to: RouteLocationNormalized, locale?: AccountLanguage) => {
  const { checkoutAdminRouteOnTenant } = useTenantService();
  const resourceName = to.meta.resourceName;
  if (!resourceName) return false;
  return await checkoutAdminRouteOnTenant(resourceName, locale);
};

export default router;
