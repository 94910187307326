import { TenantConfig } from '@/router/tenantConfig';

export const config: TenantConfig = {
  infoPageIdsToShow: ['business-bombshell'],
  UNICORN_PAGE_URL: 'https://bombshell.linkmate.one',
  postHogDashboardLink: '',

  leadRadarLink: '',
  deprecatingOldInfoPages: false,
  customCsvImportService: [],
  hideLastOrder: true,
  hideShopLink: false,
  hideInfoPageAnalytic: false,
  hideLeadMagnetAnalytic: false,
  hideLeaderboard: false,
  showRegistrationLink: false,
  showBundles: [],
  menuItemsToHide: ['StatisticsPage', 'InfoPageLinkPage'],
  sourcesToShow: ['Manually Created', 'CONTACT_ME_FORM', 'INFORMATION_FLOW', 'LEAD_MAGNET', 'CSV'],
  membershipsToShow: ['Lead', 'Customer', 'Promoter', 'Lost'],
  interestsToShow: ['business', 'product'],
  hideColdInfoPage: false,
  showSSP: false,
  isB2C: false,
  showRequestedLeadsInSchedulerComponent: false,
  hideInfoPages: false,
};
