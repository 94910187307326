import { useUserStore } from '@/stores/userStore';
import { formatDate, UseDateFormatOptions } from '@vueuse/core';
import { DateTime } from 'luxon';
import { FormatOption } from './utilsService';
import { getCurrentLocale } from '@/plugins/i18nPlugin';

export function useDateTimeService() {
  const DAY_IN_SECONDS = 1000 * 3600 * 24;

  function toDateTime(date: Date | string | DateTime): DateTime {
    if (date instanceof DateTime) {
      return date;
    }

    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }

    return DateTime.fromISO(date);
  }

  function formatTime(date: Date | string | DateTime): string {
    const dt = toDateTime(date);
    return dt.toFormat('HH:mm');
  }

  const getStartOfTheDay = (date: Date) => {
    const tmp = new Date(date);
    tmp.setHours(0, 0, 0, 0);
    return tmp;
  };

  const getEndOfTheDay = (date: Date) => {
    const tmp = new Date(date);
    tmp.setHours(23);
    tmp.setMinutes(59);
    tmp.setSeconds(59);
    tmp.setMilliseconds(999);
    return tmp;
  };

  const getNowDateMidday = () => {
    const tmp = new Date();
    tmp.setHours(12, 0, 0, 0);
    return tmp;
  };

  const getDateMidday = (year: number, month: number, day: number) => {
    const tmp = new Date(year, month, day);
    tmp.setHours(12, 0, 0, 0);
    return tmp;
  };

  const getStartOfCalendarMonth = (date: Date) => {
    const dt = DateTime.fromJSDate(date);
    return dt.startOf('month').startOf('day').toJSDate();
  };

  const getEndOfCalendarMonth = (date: Date) => {
    const dt = DateTime.fromJSDate(date);
    return dt.endOf('month').endOf('day').toJSDate();
  };

  const compareDates = (d1: Date, d2: Date, compareFunc: (n1: number, n2: number) => boolean) => {
    const year1 = d1.getFullYear();
    const year2 = d2.getFullYear();
    if (year1 != year2) {
      return compareFunc(year1, year2);
    }
    const month1 = d1.getMonth();
    const month2 = d2.getMonth();
    if (month1 != month2) {
      return compareFunc(month1, month2);
    }
    const date1 = d1.getDate();
    const date2 = d2.getDate();
    return compareFunc(date1, date2);
  };

  const calculateStartOfDayFromToday = (days: number) => {
    const dateToReturn = new Date();
    dateToReturn.setDate(dateToReturn.getDate() + days);
    dateToReturn.setHours(0, 0, 0, 0);
    return dateToReturn;
  };

  const calculateDatesDifference = (d1: Date, d2: Date) => {
    const differenceInTime = Math.abs(d1.getTime() - d2.getTime());
    const differenceInDays = differenceInTime / DAY_IN_SECONDS;
    return Math.floor(differenceInDays);
  };

  const getFormattedDate = (args: {
    dateString?: Date | string;
    withTime?: boolean;
    formatOptions?: FormatOption;
    useDotNotation?: boolean; // if true, the date will be formatted like "2024.03.15 - 14:30", otherwise it will be like today, yesterday, 15 Mar 2024.
  }) => {
    const { dateString, withTime = false, formatOptions, useDotNotation = false } = args;
    const userStore = useUserStore();
    if (!dateString) return;
    const dt = typeof dateString === 'string' ? new Date(dateString) : dateString;

    const options: UseDateFormatOptions = {
      locales: userStore.adminStatus.adminModeLanguage || userStore.loggedInInfo?.user?.accountLanguage || getCurrentLocale(),
    };

    if (useDotNotation) {
      if (withTime) {
        return formatDate(dt, formatOptions?.formatString || 'YYYY.MM.DD - HH:mm', options);
      }
      return formatDate(dt, formatOptions?.formatString || 'YYYY.MM.DD', options);
    }

    const relativeDate = getRelativeDate(dt);
    if (withTime) {
      return `${relativeDate} - ${formatTime(dt)}`;
    }
    return relativeDate;
  };

  const getDateInfoAsObject = (dateString?: string) => {
    if (!dateString) return;
    const userStore = useUserStore();
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return;
    }
    const locale = userStore.adminStatus.adminModeLanguage || userStore.loggedInInfo?.user?.accountLanguage || getCurrentLocale();
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const timeOptions: Intl.DateTimeFormatOptions = { hourCycle: 'h12', hour: 'numeric', minute: '2-digit' };
    return {
      date: new Intl.DateTimeFormat(locale, dateOptions).format(date),
      time: new Intl.DateTimeFormat('en-GB', timeOptions).format(date),
      zoneOffset: getTimezoneOffset(date),
    };
  };

  const getTimezoneOffset = (date: Date) => {
    return date.getTimezoneOffset() * -1;
  };

  const getRelativeDate = (date: Date | string | DateTime, shortMonth: boolean = false): string => {
    const userStore = useUserStore();
    const dt = toDateTime(date);
    const now = DateTime.now();
    const locale = userStore.adminStatus.adminModeLanguage || userStore.loggedInInfo?.user?.accountLanguage || getCurrentLocale();

    if (dt.hasSame(now, 'day')) {
      return (
        new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(0, 'day').charAt(0).toUpperCase() +
        new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(0, 'day').slice(1)
      );
    }

    if (dt.hasSame(now.minus({ days: 1 }), 'day')) {
      return (
        new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(-1, 'day').charAt(0).toUpperCase() +
        new Intl.RelativeTimeFormat(locale, { numeric: 'auto' }).format(-1, 'day').slice(1)
      );
    }

    return getSmartDate(date, shortMonth);
  };

  const getSmartDate = (date: Date | string | DateTime, shortMonth: boolean = false): string => {
    const userStore = useUserStore();
    const dt = toDateTime(date);
    const now = DateTime.now();
    const locale = userStore.adminStatus.adminModeLanguage || userStore.loggedInInfo?.user?.accountLanguage || getCurrentLocale();

    if (dt.hasSame(now, 'year')) {
      const monthFormat = shortMonth ? 'MMM' : 'MMMM';
      return dt.setLocale(locale).toFormat(`d ${monthFormat}`);
    }

    return dt.setLocale(locale).toFormat(`d MMM yyyy`);
  };

  function getCalendarDaysRange({
    month,
    year,
    firstDayOfWeek = 'monday', // 'monday' (default) or 'sunday'
  }: {
    month: number;
    year: number;
    firstDayOfWeek?: 'sunday' | 'monday';
  }) {
    const firstDayOfMonth = new Date(year, month, 1);

    // Calculate the day of week for the first day of month (0 = Sunday, 6 = Saturday)
    let firstDayWeekday = firstDayOfMonth.getDay();

    // If day is Sunday (0) and first day of week is Monday, we need to go back 6 days (not 0)
    if (firstDayOfWeek === 'monday') {
      firstDayWeekday = firstDayWeekday === 0 ? 6 : firstDayWeekday - 1;
    }

    // Calculate the first visible day
    const firstVisibleDay = new Date(firstDayOfMonth);
    firstVisibleDay.setDate(firstVisibleDay.getDate() - firstDayWeekday);

    // Create last day - we always want to return 42 days (6 weeks)
    const lastVisibleDay = new Date(firstVisibleDay);
    lastVisibleDay.setDate(firstVisibleDay.getDate() + 41); // 42 days - 1

    return {
      firstDay: firstVisibleDay,
      lastDay: lastVisibleDay,
    };
  }

  return {
    toDateTime,
    formatDate,
    formatTime,
    getRelativeDate,
    getSmartDate,
    getNowDateMidday,
    getDateMidday,
    compareDates,
    getEndOfTheDay,
    getStartOfTheDay,
    getFormattedDate,
    calculateDatesDifference,
    getDateInfoAsObject,
    getTimezoneOffset,
    getStartOfCalendarMonth,
    getEndOfCalendarMonth,
    calculateStartOfDayFromToday,
    getCalendarDaysRange,
  };
}
