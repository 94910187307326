import { TenantConfig } from '@/router/tenantConfig';

export const config: TenantConfig = {
  infoPageIdsToShow: ['interestProduct', 'interestIncome'],
  UNICORN_PAGE_URL: '',
  postHogDashboardLink: 'https://us.posthog.com/shared/hQZB2g_OKpGoGcLhC1BBcJgjOrv9IQ',
  customCsvImportService: ['pruvit'],
  deprecatingOldInfoPages: true,
  leadRadarLink: 'empire_infopages_content/',
  hideLastOrder: false,
  hideShopLink: false,
  hideInfoPageAnalytic: false,
  hideLeadMagnetAnalytic: false,
  hideLeaderboard: false,
  showRegistrationLink: false,
  showBundles: [],
  menuItemsToHide: ['InfoPageLinkPage'],
  sourcesToShow: [
    'CSV',
    'Manually Created',
    'CONTACT_ME_FORM',
    'INFORMATION_FLOW_FORM',
    'INFORMATION_FLOW',
    'LEAD_MAGNET',
    'US bot',
    'DE bot',
    'ES bot',
    'EN bot',
  ],
  membershipsToShow: ['Lead', 'User', 'Customer', 'Affiliate', 'Smartship', 'Promoter', 'Lost'],
  interestsToShow: ['business', 'product'],
  isB2C: false,
  showSSP: false,
  hideColdInfoPage: false,
  showRequestedLeadsInSchedulerComponent: false,
  hideInfoPages: false,
};
